import React, { useEffect, useState, useRef } from "react";
import "../../index.scss";
import { convertFilterToString } from "../../helpers/convertToFilter";
import { URL_VPOCKET, API_VERSION } from "../../helpers/constants";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "react-bootstrap";
import Notifications from '@mui/icons-material/NotificationsRounded';
import DateAdapter from "@mui/lab/AdapterMoment";
// import Switch from '@mui/material/Switch';
import Logo from "../../assets/img/icons/logoclear.svg";
// import Lupa from "../../assets/img/icons/lupa.svg";
// import NumberFormat from "react-number-format";
// import Visa from "../../assets/img/icons/visa.svg";
import Master from "../../assets/img/icons/masterCard.svg";
// import Discover from "../../assets/img/icons/discover.svg";
// import American from "../../assets/img/icons/americanexpress.svg";
import clearcard from "../../assets/img/icons/LightBlue.svg";
// import editBtn from "../../assets/img/icons/editBtn.svg";
import reportMoney from "../../assets/img/icons/reportMoney.svg";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Autocomplete,    
    TextField
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import PropTypes from 'prop-types';
import {
    downloadReport,
} from "../../actions/genericAction";
import { Badge } from "@mui/icons-material";
import Style from "../Layouts/genericStyles.module.scss";
// import {
//     getCardPurchases,
// } from "../../actions/homeAction";
// import ModalNew from "../Layouts/ModalNew";
import { formatter } from "../../helpers/helpers";
import { donutOptions, baroptions } from "../../helpers/apexOptions";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
// import PersonIcon from '@mui/icons-material/Person';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const optionMonths = [
    { label: "Todos", value: null },
    { label: "Enero", value: 1 },
    { label: "Febrero", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Mayo", value: 5 },
    { label: "Junio", value: 6 },
    { label: "Julio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Septiembre", value: 9 },
    { label: "Octubre", value: 10 },
    { label: "Noviembre", value: 11 },
    { label: "Diciembre", value: 12 }
];

function Reports(props) {
    const counter = useSelector((state) => state);
    const history = useHistory();
    // const [trigger, setTrigger] = useState(1);
    // const dispatch = useDispatch();

    const token = counter?.loginReducer?.Authorization;
    const [isLoading, setIsLoading] = useState(false);
    const [userExpenses, setUserExpenses] = useState([]);
    const [typeExpenses, setTypeExpenses] = useState([]);
    const [typeExpensesTotal, setTypeExpensesTotal] = useState(1);
    const [summaryMonth, setSummaryMonth] = useState({});
    const [summaryMonthTotal, setSummaryMonthTotal] = useState(1);

    const [data, setData] = useState({
        showEdit: false,
        excelReport:null,
        yearSelected : null,
        month:null
    });
    
    const selectedCard = counter.genericReducer.selectedUserCard;    
    const TOKEN = counter.loginReducer.Authorization;    

    useEffect(() => {
        if (!selectedCard.ccar_id) {
            history?.push("/home");
        } else {
            getAllReports();
        }
    }, [selectedCard?.ccar_id, data]);

    const getAllReports = async () => {
        setIsLoading(true);
        const yearSelected = data.yearSelected?._d.getFullYear() ?? new Date().getFullYear();
        const monthSelected = data.month;
        const [my_userExpenses, my_typeExpenses, my_summaryMonth] = await Promise.all(
            [
                getReportsByType({
                    reportType: "userExpenses",
                    ccar_id: selectedCard?.ccar_id,
                    year: yearSelected,
                    month:monthSelected
                }),
                getReportsByType({
                    reportType: "typeExpenses",
                    ccar_id: selectedCard?.ccar_id,
                    year: yearSelected,
                    month:monthSelected
                }),
                getReportsByType({
                    reportType: "summaryMonth",
                    ccar_id: selectedCard?.ccar_id,
                    year: yearSelected,
                    month:monthSelected
                }),
            ]
        );


        setUserExpenses(my_userExpenses || []);

        if (my_typeExpenses && Array.isArray(my_typeExpenses)) {
            setTypeExpenses(my_typeExpenses);
            const calctotal = my_typeExpenses
                .map((obj) => obj?.totalExpend)
                .reduce((prev, current) => {
                    if (current) return prev + current;
                    return prev;
                }, 0);
            setTypeExpensesTotal(calctotal);
        } else {
            setTypeExpenses([]);
        }

        if (my_summaryMonth && Array.isArray(my_summaryMonth.data)) {
            setSummaryMonth(my_summaryMonth);
            const calctotal_m = my_summaryMonth.data.reduce((prev, current) => {
                if (current) return prev + current;
                return prev;
            }, 0);
            setSummaryMonthTotal(calctotal_m);
        } else {
            setSummaryMonth([]);
        }
      
        setIsLoading(false);
    };

    const getReportsByType = async (data) => {
        ///payroll/payroll-template-detail-news/?id=1019&entity_account=12

        let str_filters = convertFilterToString(data);
        return await fetch(`${URL_VPOCKET}${API_VERSION}reports?${str_filters}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.success) {
                    return res.results;
                } else {
                    return null;
                }
            })
            .catch((err) => {
                console.error(err.message);
                return null;
            });
    };

    const buttonDownload = useRef() 
    const handleExcelReport = async(params, token) => {
        const MySwal = withReactContent(Swal);
        if (data.yearSelected && data.month){
            const response = await downloadReport(params, token);
            if (!response.success){            
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.message                
                });
            }else{
                setData({...data, excelReport:response.results})
                buttonDownload.current.click()
            }
        }else{
            MySwal.fire({icon:"error", title:"Error", text:"Debe seleccionar año y mes para generar el informe"})

        }
    }


    const renderCard = () => {
        return (
            <Row className="mt-4" >
                <Col
                    className={Style.cardCredit}
                    style={{ flex: 10, backgroundImage: `url(${clearcard})`, backgroundSize: "cover" }}
                >
                    <div style={{ flex: 10 }}>
                        <p className="nameBank" style={{ margin: 0, padding: 0 }}>{selectedCard?.bank}</p>
                        <p className="codeBank" style={{ marginTop: 4, margin: 0, padding: 0 }}>{selectedCard?.ccarNbr}</p>
                    </div>
                    <div style={{ flex: 1, margin: 0, padding: 0, alignSelf: "center" }}>
                        <img src={Master} style={{ width: 60 }} alt="1" />
                    </div>
                </Col>
            </Row >
        )
    }

    const renderGastoUsuarios = () => {
        if (Array.isArray(userExpenses)) {
            return userExpenses.map((item, index) => {
                return (
                    <Row className="border border-primary mt-3 mx-1 p-3" style={{ borderRadius: "15px" }} >
                        <Col className="align-self-center p-0" xs={2}>
                            <PersonRoundedIcon
                                sx={{
                                    color: item.status === "enabled" ?
                                        "#0E6ABA"
                                        :
                                        "#2DCB95"
                                    , fontSize: "40px"
                                }}
                            />
                        </Col>
                        <Col xs={10}>
                            <div>
                                <span class="textC-deepBlue">
                                    {item.usr_names} {item.usr_surnames}
                                </span>
                            </div>
                            <div>
                                <b class=" textC-deepBlue">
                                    {formatter.format(item.totalExpend)} gastados
                                </b>
                            </div>
                        </Col>
                    </Row>
                )
            })
        } else {
            return [];
        }
    }

    const renderGastosTipo = () => {
        if (Array.isArray(typeExpenses)) {
            return typeExpenses.map((item, index) => {
                return (
                    <Col key={index} xs={12} md={6} style={{ padding: 8 }} >
                        <div className="border border-primary d-flex flex-column" style={{ borderRadius: "15px", padding: 16 }} >
                            <Chart
                                options={donutOptions({
                                    labels: [item?.ext_name ? item?.ext_name : "Sin datos", "Otros"],
                                    colors: ["#2DCB95", "#CBEAFB"]
                                })}
                                series={[item?.totalExpend ? item?.totalExpend : 0, typeExpensesTotal - item?.totalExpend]}
                                type="donut"
                                //width={200}
                                height={200}
                            />
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                    <b class="textC-deepBlue">
                                        {item?.ext_name ? item?.ext_name : "Sin datos"}
                                    </b>
                                    <span class="textC-deepBlue">
                                        {formatter.format(item.totalExpend)}
                                    </span>
                                </div>
                                <div className="d-flex">
                                    <p style={{ margin: 0, alignSelf: "center" }}>
                                        {item.totalExpend !== 0 ? parseFloat(item.totalExpend / typeExpensesTotal).toFixed(2) : 0}
                                        {/* {Math.round(item.totalExpend / typeExpensesTotal * 100) / 100} */}
                                        %
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                )
            })
        } else {
            return []
        }
    }        

    const yearFilter = data.yearSelected?._d.getFullYear() ?? new Date().getFullYear()
    
    const paramsExcel = {
        ccar_id:selectedCard.ccar_id,
        dateFrom: new Date(yearFilter, data?.month-1)?.toISOString().slice(0, 10),
        dateUntil: new Date(yearFilter, data?.month)?.toISOString().slice(0, 10)
    }

    return (
        <div>

            <div className="bgHeaderC-deepBlue d-flex flex-column" >
                <Row
                    className="px-4 pt-3 justify-content-between"
                    style={{ flex: 6 }}
                >
                    <Col xs={10}>
                        <img src={Logo} alt="logo" />
                    </Col>
                    <Col
                        className="bgNotifi"
                        xs={2}
                        onClick={() => history?.push("/home/notificaciones")}
                    >
                        <Badge
                            color="primary"
                            variant="dot"
                            invisible={counter.homeReducer.totalNoRead >= 1 ? false : true}
                        >
                            <Notifications
                                sx={{ color: "#0db7e1" }}
                            />
                        </Badge>
                    </Col>
                </Row>
                <Row
                    className="px-4 justify-content-between"
                    style={{
                        flex: 4,
                        paddingBottom: 9,
                        marginTop: 47
                    }}
                >
                    <Col>
                        <h1
                            className="text-white fw-bold"
                            style={{
                                margin: 0,
                                fontSize: 40,
                                //lineHeight: "47px"
                            }}
                        >
                            Informes
                        </h1>
                        <h2
                            className="text-white text-conexion"
                            style={{
                                margin: 0,
                                fontSize: 14,
                                lineHeight: "16px"
                            }}
                        >
                            {Array.isArray(counter.genericReducer.ListUser) ?
                                counter.genericReducer.ListUser.length
                                :
                                0
                            }
                            {" "}usuarios activos
                        </h2>
                    </Col>
                    <Col>
                      <LocalizationProvider dateAdapter={DateAdapter} >
                        <DatePicker
                            views={['year']}
                            value={data.yearSelected}
                            onChange={( newValue ) => {
                                setData({...data, yearSelected: newValue})
                            }}
                            renderInput={(params) => <TextField {...params} label="Año" sx={{
                                svg:{color:'#fff'},
                                label:{color:'#fff'},
                                input:{color:'#fff'},
                                fieldset:{borderColor: '#fff'},
                                width:'100%'
                            }}  />}
                        />
                        </LocalizationProvider>
                    </Col>
                    <Col>
                        <Autocomplete
                            id="ban_id"
                            style={{ flex: 1 }}
                            renderInput={(params) => <TextField {...params} label="Mes" sx={{
                                svg:{color:'#fff'},
                                label:{color:'#fff'},
                                input:{color:'#fff'},
                                fieldset:{borderColor: '#fff'}
                            }}  />}
                            value={optionMonths.find(m => m.value === data.months)}
                            options={optionMonths}
                            onChange={( e, newValue) => {
                                setData({
                                    ...data,
                                    month: newValue.value
                                });
                            }}
                        />
                    </Col>
                </Row>
            </div >

            <div style={{ padding: "35px 32px" }}>
                {renderCard()}
            </div>


            <div style={{ marginTop: 20 }}>
                <Accordion
                    className={Style.reportAccordion}
                    style={{ marginBottom: 24 }}
                    sx={{
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                        className={Style.reportAccordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={Style.sectionTitle} style={{ margin: 0, marginBottom: 0 }}>
                            Gastos por usuario
                        </p>
                    </AccordionSummary>

                    <AccordionDetails>
                        {renderGastoUsuarios()}
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    className={Style.reportAccordion}
                    style={{ marginBottom: 24 }}
                    sx={{
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                        className={Style.reportAccordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={Style.sectionTitle} style={{ margin: 0, marginBottom: 0 }}>
                            Gastos segun tipo
                        </p>
                    </AccordionSummary>

                    <AccordionDetails>
                        <div className="d-flex flex-wrap">
                            {renderGastosTipo()}
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    className={Style.reportAccordion}
                    style={{ marginBottom: 24 }}
                    sx={{
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                        className={Style.reportAccordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={Style.sectionTitle} style={{ margin: 0, marginBottom: 0 }}>
                            Resumen Mensual
                        </p>
                    </AccordionSummary>

                    <AccordionDetails>
                        <div className="d-flex">
                            <img
                                src={reportMoney}
                                alt="Gastos"
                                style={{ alignSelf: "start" }}
                            />
                            <div>
                                <p
                                    style={{
                                        margin: 0,
                                        fontWeight: "bold",
                                    }}
                                >Total gastado en los últimos 12 meses</p>
                                <p>{formatter.format(summaryMonthTotal)}</p>
                            </div>
                        </div>
                        {(Array.isArray(summaryMonth.data) && Array.isArray(summaryMonth.months)) &&
                            <ReactApexChart
                                options={
                                    baroptions({
                                        ...summaryMonth,
                                        showLegend: false,
                                    })
                                }
                                series={[
                                    {
                                        name: "Gastos",
                                        data: summaryMonth.data
                                    }
                                ]}

                                type="bar"
                                height={350}
                            />
                        }
                    </AccordionDetails>
                </Accordion>
            </div>

        <div className="d-flex justify-content-center my-2">                
            <button className="btn btn-outline-success" onClick={()=>handleExcelReport(paramsExcel, TOKEN)}>
                Descargar informe
            </button>
            <a ref={buttonDownload}
                rel="noreferrer" download="INFORME-GASTOS-VPOCKET.xlsx" alt="Descargar informe"
                href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data?.excelReport}`} className="d-none"/>
        </div>

        </div >
    )


}

export default Reports;