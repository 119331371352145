// import Swal from "sweetalert2";
// import moment from "moment";
// function to validate a email structure
import withReactContent from "sweetalert2-react-content";

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        // const MySwal = withReactContent(Swal);
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
});


export const todayDateFormat = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return  yyyy + '-' + mm + '-' + dd;
}