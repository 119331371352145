import { convertFilterToString } from "../helpers/convertToFilter";
import { URL_VPOCKET, API_VERSION } from "../helpers/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
    GET_LIST_BANK,
    GET_LIST_USER,
    GET_ROLE_TYPE,
    GET_USERS_BY_CARD,
    GET_JOB_POSITIONS,
    GET_TYPE_TRANSACTION,
    GET_TYPE_EXPENSE,
    GET_APPLICATIONS_APPROVED,
    GET_LIST_COMPANIES,
    LOGOUT,
} from "./actionTypes";
const MySwal = withReactContent(Swal);

export const listBank = (objFilters) => (dispatch, getCreate) => {
    // let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}banks`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {

                const { results, rowTotal } = res;
                dispatch({
                    type: GET_LIST_BANK,
                    payload: results,
                    total: rowTotal,
                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar el listado de bancos",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const userByCard = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}userByCard?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                const { results, rowTotal } = res;
                dispatch({
                    type: GET_USERS_BY_CARD,
                    payload: results,
                    total: rowTotal,
                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar el listado de usuarios",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const listUser = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}users?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                const { results, rowTotal } = res;
                dispatch({
                    type: GET_LIST_USER,
                    payload: results,
                    total: rowTotal,
                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar el listado de usuarios",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const createCardCredit = (data, doAfter) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}userCards`, {
        method: "POST",
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                if (res.success) {
                    dispatch({
                        type: GET_USERS_BY_CARD,
                        payload: [],
                        totalUserByCArd: 0
                    })
                    MySwal.fire({
                        icon: "success",
                        title: res.message,
                    })
                    doAfter()
                } else {
                    dispatch({
                        type: GET_USERS_BY_CARD,
                        payload: [],
                        totalUserByCArd: 0
                    })
                    MySwal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.message,
                        footer: "Si el problema persiste comuníquese con un asesor.",
                    });
                }
            }
        })
        .catch((res) => {
            dispatch({
                type: GET_USERS_BY_CARD,
                payload: [],
                totalUserByCArd: 0
            })
            MySwal.fire({
                icon: "error",
                title: "Error al registrar la tarjeta",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
}
export const updatePassword = (data, doAfter) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}users`, {
        method: "PUT",
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                if (res.success) {
                    MySwal.fire({
                        icon: "success",
                        title: res.message,
                    })
                    doAfter()
                } else {
                    MySwal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.message,
                        footer: "Si el problema persiste comuníquese con un asesor.",
                    });
                }
            }
        })
        .catch((res) => {
            MySwal.fire({
                icon: "error",
                title: "Error al cambiar la contraseña",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
}

export const updateUserInfo = (data, doAfter = null) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}userInfo`, {
        method: "PUT",
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                if (res.success) {
                    MySwal.fire({
                        icon: "success",
                        title: res.message,
                    })
                    if (doAfter) {
                        doAfter()
                    }
                } else {
                    MySwal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.message,
                        footer: "Si el problema persiste comuníquese con un asesor.",
                    });
                }
            }
        })
        .catch((res) => {
            MySwal.fire({
                icon: "error",
                title: "Error al editar el usuario",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });

}

export const roleTypes = (objFilters) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}roleTypes`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                const { results, rowTotal } = res;
                dispatch({
                    type: GET_ROLE_TYPE,
                    payload: results,
                    total: rowTotal,
                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar el listado de roles",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const jobPositions = (objFilters) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}jobPositions`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                const { results, rowTotal } = res;
                dispatch({
                    type: GET_JOB_POSITIONS,
                    payload: results,
                    total: rowTotal,
                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar el listado de cargos",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const createNewUser = (data, doAfter) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}users`, {
        method: "POST",
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                if (res.success) {
                    MySwal.fire({
                        icon: "success",
                        title: res.message,
                    })
                    doAfter()
                } else {
                    MySwal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.message,
                        footer: "Si el problema persiste comuníquese con un asesor.",
                    });
                }
            }
        })
        .catch((res) => {
            MySwal.fire({
                icon: "error",
                title: "Error al registrar el nuevo usuario",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
}
export const editCreditCard = (data, doAfter) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}userCards`, {
        method: "PUT",
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                if (res.success) {
                    MySwal.fire({
                        icon: "success",
                        title: res.message,
                    })
                    doAfter()
                } else {
                    MySwal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.message,
                        footer: "Si el problema persiste comuníquese con un asesor.",
                    });
                }
            }
        })
        .catch((res) => {
            MySwal.fire({
                icon: "error",
                title: "Error al editar la tarjeta",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
}
export const transactionTypes = (objFilters) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}transactionTypes`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then(() => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                const { results, rowTotal } = res;
                dispatch({
                    type: GET_TYPE_TRANSACTION,
                    payload: results,
                    total: rowTotal,
                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar el listado de tipos de transacción",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const expenseTypes = (objFilters) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}expenseTypes`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then(() => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                const { results, rowTotal } = res;
                dispatch({
                    type: GET_TYPE_EXPENSE,
                    payload: results,
                    total: rowTotal,
                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar el listado de tipos de gastos",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const createPurchase = (data, doAfter, thirdAction) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}purchase`, {
        method: "POST",
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                if (res.success) {
                    // MySwal.fire({
                    //     icon: "success",
                    //     title: res.message,
                    // })
                    doAfter()
                    if (!!thirdAction) thirdAction()
                } else {
                    doAfter()
                    MySwal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.message,
                        footer: "Si el problema persiste comuníquese con un asesor.",
                    });
                }
            }
        })
        .catch((res) => {
            doAfter()
            MySwal.fire({
                icon: "error",
                title: "Error al registrar",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
}
export const createPayments = (data, doAfter) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}payments`, {
        method: "POST",
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                if (res.success) {
                    MySwal.fire({
                        icon: "success",
                        title: res.message,
                    })
                    doAfter("ok")
                } else {
                    doAfter()
                    MySwal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.message,
                        footer: "Si el problema persiste comuníquese con un asesor.",
                    });
                }
            }
        })
        .catch((res) => {
            doAfter()
            MySwal.fire({
                icon: "error",
                title: "Error al registrar el papgo",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
}
export const applicationsApproved = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}purchase?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                const { results, rowTotal } = res;
                dispatch({
                    type: GET_APPLICATIONS_APPROVED,
                    payload: results,
                    total: rowTotal,
                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar el listado de solicitudes aprobadas",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};


export const downloadReport = async (params, token) => {
    if (!params) {
        return { success: false, message: "Debe escoger el mes para generar el informe" }
    }
    if (!token) {
        console.error(token, "Auth")
        return { success: false, message: "No tiene permisos para realizar esta acción" }
    }
    try {
        const filters = convertFilterToString(params)
        const query = await fetch(`${URL_VPOCKET}${API_VERSION}generateReport?${filters}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token
            }
        })
        const response = await query.json()
        return response

    } catch (error) {
        console.error(error)
        return { success: false, message: "Ocurrió un error al generar el informe" }
    }
}
export const listCompanies = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}companies`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                const { results, rowTotal } = res;
                dispatch({
                    type: GET_LIST_COMPANIES,
                    payload: results,
                    total: rowTotal,
                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar el listado de empresa",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
