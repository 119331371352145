import {formatter} from "./helpers";

//======================= APEX CHARTS OPTIONS ==============================================
//======================= APEX CHARTS OPTIONS ==============================================
//======================= APEX CHARTS OPTIONS ==============================================
export const donutOptions = (props) => {
    const donutOptions = {
      donut: {
        size: "100%",
      },
      theme: {
        palette: "palette2", // upto palette10
      },
      colors: props?.colors
        ? props?.colors
        : [
            "#43AA8B",
            "#90BE6D",
            "#FFD500",
            "#FF8B00",
            "#00296B",
            "#8D5B4C",
            "#F86624",
            "#449DD1",
            "#A300D6",
            "#546E7A",
            "#A5978B",
            "#449DD1",
            "#2E294E",
            "#FF4560",
          ],
      fill: {
        // colors: [
        //   "#00296B",
        //   "#3180DC",
        //   "#9C27B0",
        //   "#43AA8B",
        //   "#90BE6D",
        //   "#FFD500",
        //   "#FFA200",
        //   // "red",
        // ],
      },
      legend: {
        position: props?.legendPosition ? props.legendPosition : "bottom",
      },
      options: {
        chart: {
          id: "apexchart-example",
        },
      },
  
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: "50%",
            background: "transparent",
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                formatter: function (val) {
                  return val;
                },
              },
              value: {
                show: false,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: false,
                showAlways: true,
                label: "Total",
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      labels: props?.labels
        ? props.labels
        : ["Uno", "Dos", "Tres", "Cuatro", "Cinco"],
    };
    return donutOptions;
  };


export  const baroptions = (props) =>({
    chart: {
        height: 350,
        type: 'bar',
    },
    colors: [
                "#2DCB95",
                "#CBEAFB"
    ],
    plotOptions: {
        bar: {
            borderRadius: 12,
            dataLabels: {
            position: 'top',
            },
        }
    },
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return formatter.format(val);
        },
        offsetY: -20,
        style: {
            fontSize: '12px',
            colors: ["#304758"]
        }
    },

    xaxis: {
        categories: props?.months?  props.months : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'bottom',
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
        crosshairs: {
            fill: {
            type: 'gradient',
            gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
            }
            }
        },
        tooltip: {
            enabled: true,
        }
    },
    yaxis: {
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false,
        },
        labels: {
            show: false,
            formatter: function (val) {
            return val + "%";
            }
        }
    }
    })

//END ======================= APEX CHARTS OPTIONS ==============================================
//END ======================= APEX CHARTS OPTIONS ==============================================
//END ======================= APEX CHARTS OPTIONS ==============================================