import React, { useEffect, useState } from "react";
import "../../index.scss"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "react-bootstrap";
import Notifications from '@mui/icons-material/NotificationsRounded';
import Switch from '@mui/material/Switch';
import Clip from "../../assets/img/icons/clip.svg";
import modalnewBack from "../../assets/img/icons/modalnewBack.svg";
import Logo from "../../assets/img/icons/logoclear.svg";
import Lupa from "../../assets/img/icons/lupa.svg";
import Master from "../../assets/img/icons/masterCard.svg";
import clearcard from "../../assets/img/icons/LightBlue.svg";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Autocomplete,
    TextField
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Badge } from "@mui/icons-material";
import Style from "../Layouts/genericStyles.module.scss";
import {
    getCardPurchases,
} from "../../actions/homeAction";
import ModalNew from "../Layouts/ModalNew";
import { formatter } from "../../helpers/helpers";

function statusStyling(status) {
    let textTranslate = "";
    let colorTranslate = "";

    switch (status) {
        case "approved":
            textTranslate = "Aprobado";
            colorTranslate = Style.approved;
            break;
        case "reqAuth":
            textTranslate = "Requiere Autorización";
            colorTranslate = Style.reqAuth;
            break;
        default:
            textTranslate = status;
            break;
    }

    return <div className={`${Style.statusStyling} ${colorTranslate}`}>
        <span></span>
        <p>{textTranslate}</p>
    </div>
}

const optionMonths = [
    { label: "Todos", value: 0 },
    { label: "Enero", value: 1 },
    { label: "Febrero", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Mayo", value: 5 },
    { label: "Junio", value: 6 },
    { label: "Julio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Septiembre", value: 9 },
    { label: "Octubre", value: 10 },
    { label: "Noviembre", value: 11 },
    { label: "Diciembre", value: 12 }
];

function UserTransactions(props) {
    const counter = useSelector((state) => state);
    const history = useHistory();
    const [trigger, setTrigger] = useState(1);
    const dispatch = useDispatch();
    const permissions = counter?.loginReducer?.user_data?.permissions;

    const myData = {
        ...counter.genericReducer.selectedUserDetail,
    }
    const selectedCard = counter.genericReducer.selectedUserCard;

    const [data, setData] = useState({
        showEdit: false,
        ...myData
    });
    const [modalData, setModalData] = useState({
        show: false,
    })

    useEffect(() => {
        if (!myData?.usr_id) {
            history?.push("/home/usuarios");
        }
    }, [myData?.usr_id])

    useEffect(() => {
        dispatch(
            getCardPurchases({
                ccar_id: selectedCard.ccar_id,
                usr_id: data.usr_id,
                //pay_id: ,
            })
        );
    }, [trigger]);

    let optionRoleType = [];
    if (Array.isArray(counter.genericReducer.roleTypes)) {
        counter.genericReducer.roleTypes.map((item, index) => {
            optionRoleType.push(
                { label: item.rol_name, value: item.rol_id },
            )
        })
    }
    let optionJobPositions = [];
    if (Array.isArray(counter.genericReducer.jobPositions)) {
        counter.genericReducer.jobPositions.map((item, index) => {
            optionJobPositions.push(
                { label: item.job_name, value: item.job_id },
            )
        })
    }




    const renderCard = () => {
        return (
            <Row className="mt-4" >
                <Col
                    className={Style.cardCredit}
                    style={{ flex: 10, backgroundImage: `url(${clearcard})`, backgroundSize: "cover" }}
                >
                    <div style={{ flex: 10 }}>
                        <p className="nameBank" style={{ margin: 0, padding: 0 }}>{selectedCard?.bank}</p>
                        <p className="codeBank" style={{ marginTop: 4, margin: 0, padding: 0 }}>{selectedCard?.ccarNbr}</p>
                    </div>
                    <div style={{ flex: 1, margin: 0, padding: 0, alignSelf: "center" }}>
                        <img src={Master} style={{ width: 60 }} alt="1" />
                    </div>
                </Col>
            </Row >
        )
    }

    const getmonth = (datestr) => {
        const tempdate = new Date(datestr);
        return tempdate.getMonth() + 1;
    }

    const renderTransactions = () => {
        let temparray = counter.homeReducer.cardTransaction;
        if (Array.isArray(temparray)) {
            if (data.month) {
                temparray = temparray.filter(item => getmonth(item.pur_date) == data.month)
            }
            return temparray.map((item, index) => {
                return (
                    <Accordion className={Style.purchaseCard} key={index} style={{ marginBottom: 24 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="d-flex flex-column" style={{ flex: 3 }}>
                                <p className={Style.contextTextRegular} style={{ margin: 0, marginBottom: 4 }}><b>
                                    {item.pur_date}
                                </b></p>
                                <p className={Style.sectionTitle} style={{ margin: 0, marginBottom: 0 }}>
                                    {formatter.format(item.pur_amount)}
                                </p>
                            </div>

                            <div className="d-flex flex-column" style={{ flex: 1 }}>
                                {statusStyling(item.purchaseStatus)}
                            </div>

                        </AccordionSummary>
                        <AccordionDetails>
                            <p className={Style.contextTextRegular} style={{margin:0, marginBottom:4}}> 
                                {item.pur_description} 
                            </p>
                            <p><b>
                                {item.pur_justification}
                            </b></p>
                            <div className="d-flex flex-row-reverse">
                                <div
                                    className={Style.searchBtn}
                                    onClick={() => setModalData({
                                        show: true,
                                        ...item,
                                    })}
                                >
                                    <img
                                        src={Lupa}
                                        alt="detalles"
                                    />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            })
        } else {
            return []
        }
    }

    const modalContent = (verFactura) => {
        if (verFactura) {
            return (<div>


                <img
                    src={modalnewBack}
                    onClick={() => setModalData({ ...modalData, verFactura: false })}
                />

                <img
                    src={modalData.pur_file_url}
                    className="w-100 mt-3"
                    style={{ objectFit: "contain" }}
                />

            </div>)
        } else {
            return (<div>
                {statusStyling(modalData.purchaseStatus)}

                <TextField
                    disabled={true}
                    label="Tarjeta utilizada"
                    className="w-100 mt-3"
                    value={modalData.ccar_nbr}
                />
                <TextField
                    disabled={true}
                    label="Concepto"
                    className="w-100 mt-3"
                    value={modalData.pur_description}
                />
                <TextField
                    disabled={true}
                    label="Tipo de gasto"
                    className="w-100 mt-3"
                    value={
                        (modalData.put_id === 1) ?
                            "Registro de compra"
                            :
                            (modalData.put_id === 2) ?
                                "Solicitud de compra"
                                :
                                "N/A"
                    }
                />


                <TextField
                    disabled={true}
                    label="Fecha"
                    className="w-100 mt-3"
                    value={modalData.pur_date}
                />
                <TextField
                    disabled={true}
                    label="Valor"
                    className="w-100 mt-3"
                    value={formatter.format(modalData.pur_amount)}
                />


                <div
                    className="d-flex"
                    style={{ marginTop: 22, marginBottom: 22 }}
                >
                    <input
                        type="checkbox"
                    />
                    <p
                        className={Style.contextTextRegular}
                        style={{ margin: 0, marginLeft: 8, alignSelf: "center" }}
                    >
                        Pago recurrente
                    </p>
                </div>

                <h2
                    className={Style.sectionTitle}
                    style={{ marginTop: 22, marginBottom: 0 }}
                >
                    Detalle de la factura
                </h2>

                <TextField
                    disabled={true}
                    label="No. Factura"
                    className="w-100 mt-3"
                    value={modalData.pur_id}
                />

                <div className={Style.col_md_right}>
                    <div
                        className={`${Style.btnBasic} ${Style.green}`}
                        onClick={() => setModalData({ ...modalData, verFactura: true })}
                    >
                        <img
                            src={Clip}
                        />
                        <p>Ver Factura</p>
                    </div>

                    <div
                        className={`${Style.btnBasic} ${Style.gray}`}
                        onClick={() => setModalData({ show: false })}
                    >
                        <p>Aceptar</p>
                    </div>
                </div>
            </div>);
        }

    }

    return (
        <div>
            <div className="bgHeaderC-deepBlue" style={{ height: "70px" }} >
                <Row className="d-flex px-4 pt-1">
                    <Col xs={10}>
                        <img className="pt-2" style={{ width: "40px" }} src={Logo} alt="logo" />
                    </Col>
                    {!!permissions?.notifications?.read ?
                        <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                            <Badge
                                color="primary"
                                variant="dot"
                            >
                                <Notifications
                                    sx={{ color: "#0db7e1" }}
                                />
                            </Badge>
                        </Col>
                        : ""}
                </Row>
            </div >

            <div
                //className={`${Style.userDetailsBG}`}
                style={{ padding: "35px 32px" }}
            >
                <div className={`d-flex p-3`}>
                    < span
                        className="material-icons textC-green"
                        onClick={
                            () => !!props.info? 
                                props?.setDetails({ show: false }) 
                            : 
                                //history?.push("/home/usuarios")
                                history?.goBack()
                            }
                        style={{ fontSize: "40px" }}
                    >
                        chevron_left
                    </span>
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <label className="display-6 fw-bold" >
                            Transacciones y solicitudes
                        </label>
                        <p
                            className={Style.contextTextRegular}
                            style={{ margin: 0 }}
                        >
                            {myData?.usr_names} {myData?.usr_surnames}
                        </p>
                    </div>
                </div>

                <div className="d-flex">
                    <div style={{ flex: 3 }}></div>

                    <Autocomplete
                        id="ban_id"
                        style={{ flex: 1 }}
                        renderInput={(params) => <TextField {...params} label="Mes" />}
                        value={optionMonths.find(m => m.value == data.month)}
                        options={optionMonths}
                        onChange={(event, newValue) => {
                            setData({
                                ...data,
                                month: newValue ? newValue.value : 0,
                            });
                        }}
                    />
                </div>

                {renderCard()}

                <div style={{ marginTop: 20 }}>
                    {renderTransactions()}
                </div>

            </div>

            <ModalNew
                show={modalData.show}
                onHide={() => setModalData({ show: false })}
                title={"Detalle de pago"}
                btnYesName={"Aceptar"}
                size={"400"}
            >
                {modalContent(!!modalData?.verFactura)}

            </ModalNew>

        </div >
    )

}
export default UserTransactions;
