import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import { styled } from "@mui/material/styles";
import "../../index.scss"
import Notifications from '@mui/icons-material/NotificationsRounded';
import Navigation from "../Nav/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Badge from '@mui/material/Badge';
import Logo from "../../assets/img/icons/logoclear.svg"
import { Autocomplete, TextField } from "@mui/material";
import { URL_VPOCKET, API_VERSION } from "../../helpers/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function Parameters(props) {
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();
    const history = useHistory();
    const MySwal = withReactContent(Swal);
    const token = counter?.loginReducer?.Authorization;
    const [data, setData] = useState({});
    const [trigger, setTrigger] = useState(1);
    const permissions = counter?.loginReducer?.user_data?.permissions;
    const [isLoading, setIsLoading] = useState(false);
    if (!permissions?.params?.create) {
        history?.push("/home")
    }
    const optionParamType = [
        { label: "Cargo", value: "job_position" },
        { label: "Tipo de Gasto", value: "expense_type" },
        { label: "Banco", value: "bank" },
    ]

    const postParamRecord = async (data) => {
        setIsLoading(true);
        const getMyStuff = await fetch(
            `${URL_VPOCKET}${API_VERSION}paramRecord`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((res) => {
                setIsLoading(false);
                if (res.success) {
                    MySwal.fire({
                        icon: "success",
                        title: "Guardado",
                        text: "Guardado con exito",
                    });
                    setData({});
                    setTrigger(trigger + 1);
                } else {
                    MySwal.fire({
                        icon: "error",
                        title: "No se Pudo Guardar",
                        text: res.message,
                    });
                }
            })
            .catch((err) => {
                setIsLoading(false);
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Error de conexion",
                });
            });
    };


    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="bgHeaderC-deepBlue" >
                <Row className="d-flex px-4 pt-3">
                    <Col xs={10}>
                        {/* <img className="pt-1 imgLogoBg" src={Logo} alt="logo" /> */}
                        <img className="pt-1" src={Logo} alt="logo" />
                    </Col>
                    {!!permissions?.notifications?.read ?
                        <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                            <Badge color="primary" variant="dot" invisible={counter?.homeReducer?.totalNoRead >= 1 ? false : true}  >
                                <Notifications
                                    sx={{ color: "#0db7e1" }}
                                />
                            </Badge>
                        </Col>
                        : ""}

                    <Row className="d-block">
                        <Col className="mt-5">
                            <span className="text-white fs-1 fw-bold mt-5">Parametrización</span>
                        </Col>
                    </Row>
                </Row>
            </div >
            <div className="p-3 mb-4">
                <Row className="d-block">
                    {/* <Col xs={12}>
                        <span className="fw-bold textC-deepBlue">Información general</span>
                    </Col> */}

                    <Col xs={12}>
                        {/* <TextField
                            className="w-100 mt-2"
                            label="Tipo"
                            type="text"
                            id="custom-css-outlined-input"
                            value={data.paramType}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    paramType: e.target.value,
                                })
                            }
                        /> */}
                        <Autocomplete
                            //disablePortal
                            key={"type" + trigger}
                            id="paramType"
                            value={data.paramType}
                            options={optionParamType}
                            onChange={(event, newValue) => {
                                setData({
                                    ...data,
                                    paramType: newValue?.value,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}

                                    label="Tipo de Parámetro"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            key={"paramName" + trigger}
                            autoComplete='off'
                            className="w-100 mt-3"
                            label="Nombre de Parámetro"
                            type="text"
                            id="custom-css-outlined-input"
                            value={data.paramName}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    paramName: e.target.value,
                                })
                            }
                        />
                    </Col>

                    <Col xs={12} className="px-4 mt-3 text-center">
                        <div >
                            <button
                                className="w-60 btnModalPrimary"
                                onClick={() => postParamRecord(data)}
                            >
                                Guardar cambios
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
            <Navigation info={3}></Navigation>

        </div >
    )
}
export default Parameters;
