import { Component } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    useHistory,
} from "react-router-dom";
import store from "../store";
import PrivateRoute from "./PrivateRoute";
import { Provider } from "react-redux";
import Login from "../components/Login/login";
import ForgotPassword from "../components/Login/forgotPassword";
import Home from "../components/Home/home";
import ListNotification from "../components/Notificattion/notification";
import CreateCardCredit from "../components/CardCredit/createCardCredit";
import MyAccount from "../components/Account/myAccount";
import Navigation from "../components/Nav/navigation";
import Users from "../components/User/users";
import CreateNewUser from "../components/User/createNewUser";
import PurchasesMovs from "../components/Purchases/purchasesMovs";
import Parameters from "../components/Parameters/Parameters";
import UserDetails from "../components/User/UserDetails";
import UserTransactions from "../components/User/UserTransactions";
import Reports from "../components/Reports/Reports";
import SuccessScreen from "../components/Purchases/successScreen";
class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Router>
                <Provider store={store}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/login" />
                        </Route>
                        <Route exact path="/login/olvidarcontrasena" history={useHistory}>
                            <ForgotPassword />
                        </Route>
                        <Route path="/login" component={Login} history={useHistory} />
                        <PrivateRoute>
                            <Route exact path="/home" history={useHistory}>
                                <Home />
                            </Route>
                            <Route exact path="/home/notificaciones" history={useHistory}>
                                <ListNotification />
                                <Navigation></Navigation>
                            </Route>
                            <Route exact path="/home/crearTarjeta" history={useHistory}>
                                <CreateCardCredit />
                                <Navigation></Navigation>
                            </Route>
                            <Route exact path="/home/miCuenta" history={useHistory}>
                                <MyAccount />
                            </Route>
                            <Route exact path="/home/usuarios" history={useHistory}>
                                <Users />
                            </Route>
                            <Route exact path="/home/crearUsuario" history={useHistory}>
                                <CreateNewUser />
                                <Navigation></Navigation>
                            </Route>
                            <Route exact path="/home/usuarioDetalle" history={useHistory}>
                                <UserDetails />
                                <Navigation></Navigation>
                            </Route>
                            <Route exact path="/home/usuarioTransacciones" history={useHistory}>
                                <UserTransactions />
                                <Navigation></Navigation>
                            </Route>

                            <Route exact path="/home/compras" history={useHistory}>
                                <PurchasesMovs />
                                <Navigation></Navigation>
                            </Route>

                            <Route exact path="/home/parametrizacion" history={useHistory}>
                                <Parameters />
                            </Route>

                            <Route exact path="/home/reportes/" history={useHistory}>
                                <Reports />
                                <Navigation></Navigation>
                            </Route>

                            <Route exact path="/home/compras/confirmacion" history={useHistory}>
                                <SuccessScreen />
                                <Navigation/>
                            </Route>

                        </PrivateRoute>
                    </Switch>
                </Provider>
            </Router>
        );
    }
}
export default Routes;