import moment from "moment"
// This method only get a date in format ("YYYY-MM-DD")
// return dd/mm/yyyy in String format !! ⚗ 
export const convertDateToLatinFormat = (date) => {
    return moment(date).format("DD-MM-YYYY");
    // let dd = new Date(date).getUTCDate();
    // let mm = String(date).slice(5, 7);
    // let yyyy = new Date(date).getFullYear();
    // return dd + "/" + mm + "/" + yyyy;
  };
export const convertDateToLatinFormat2 = (date) => {
    return moment(date).format("DD/MM/YYYY");
    // let dd = new Date(date).getUTCDate();
    // let mm = String(date).slice(5, 7);
    // let yyyy = new Date(date).getFullYear();
    // return dd + "/" + mm + "/" + yyyy;
  };

export const monthArray = [
    "enero", "febrero", "marzo",
    "abril", "mayo", "junio",
    "julio", "agosto", "septiembre",
    "octubre", "noviembre", "diciembre"
];
