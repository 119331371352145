import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../index.scss"
import Notifications from '@mui/icons-material/NotificationsRounded';
import Navigation from "../Nav/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Badge from '@mui/material/Badge';
import Logo from "../../assets/img/icons/logoclear.svg"
import { listUser } from "../../actions/genericAction";
import ModalNew from "../Layouts/ModalNew";
import { FormControl, InputLabel, LinearProgress, ListSubheader, MenuItem, Select } from "@mui/material";
import ChevronRight from '@mui/icons-material/ChevronRightRounded';
import { listCard } from "../../actions/homeAction";
import { LocalizationProvider } from "@mui/lab";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import DateAdapter from "@mui/lab/AdapterMoment";
import { convertDateToLatinFormat2 } from "../../helpers/convertDateFormat";
import Clip from '@mui/icons-material/AttachFileRounded';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Atras from '@mui/icons-material/ArrowBackIosNewRounded';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Swal from "sweetalert2";

function CardMovement(props) {
    const counter = useSelector((state) => state);
    const permissions = counter?.loginReducer?.user_data?.permissions;
    const dispatch = useDispatch();
    const history = useHistory();
    const [trigger, setTrigger] = useState(1);
    const [trigger2, setTrigger2] = useState(1);
    const [filter, setFilter] = useState({});
    const [dataModal, setDataModal] = useState({});
    const [date, setDate] = useState();
    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        height: "80%",
        background: !!dataModal.fileUrl ? `url(${dataModal.fileUrl}) ` : "",
        p: 1,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    };
    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }
    if (counter.genericReducer.positionApp === "") {
        props?.setDetails({ showMovs: false })
    }
    if (!permissions?.movs?.read) {
        history?.push("/home")
    }
    let hoy = new Date()
    useEffect(() => {
        dispatch(listUser())
        dispatch(listCard({
            ccar_id: props.info.ccar_id,
            transactionType: !!filter ? filter : "",
            month: !!date ? date.getUTCMonth() + 1 : "",
            year: !!date ? date.getUTCFullYear() : "",
            putId:1
        }))

    }, [trigger]);
    let meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const localeMap = {        
        es:esLocale
      };
    function diaSemana(x) {
        let date = new Date(x);
        var fechaNum = date.getUTCDate();
        var mes_name = date.getMonth();
        return fechaNum + " " + meses[mes_name]
    }
    const infoModal = (e) => {
        setDataModal(e)
        setShowModal(true)
    }
    const handleDownloadFiles = () => {
        if(dataModal.purFiles.length > 0) {
            dataModal.purFiles.forEach(file => {
                if(!!file.url) window.open(file.url)
            })
        }else{
            Swal.fire({
                icon:"info",
                title:"La compra no tiene facturas asociadas"
            })
        }
    }

    const renderLastMovesList = () => {
        let tempList = [];
        if (Array.isArray(counter?.homeReducer?.card)) {
            counter?.homeReducer?.card.map((item, index) => {
                return item?.cards?.map((x) => {
                   return  x?.movs?.forEach((x) => {
                        if (!!x) {
                            tempList.push(
                                <div
                                    className="d-flex mt-3 p-2 border-top border-bottom border-primary"
                                    key={index + 7588}
                                    onClick={() => infoModal(x)}
                                >
                                    <div className="col-2 align-self-center">
                                        <span className="fw-bold textC-blue">{diaSemana(x?.date)}</span>
                                    </div>
                                    <div className="d-block col-8 ">
                                        <div>                                           
                                            <span className={`fw-bold ${x.transactionType === "Abono" ? "textC-green" : "textC-deepBlue"}`}>
                                                {x.transactionType === "Abono" ? "+" : "-"}  $ {numberWithCommas(x?.amount)}
                                            </span>                                            
                                        </div>
                                        <div>
                                            <span className="textC-gray">{x?.description}</span>
                                        </div>
                                        <div>
                                            <span className="textC-gray" style={{ fontWeight: "600", fontSize: "14px" }}>
                                                {x?.usrFullName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-2 align-self-center">
                                        <ChevronRight sx={{ color: "#114562", fontSize: "40px" }} />
                                    </div>
                                </div>
                            )
                        }
                    })
                })
            })
        }
        return tempList;
    }
    const close = () => {
        setShowModal(false)
    }
    const search = (e) => {
        setFilter(e);
        setTrigger(trigger + 1)
    }
    const searchMonth = (e) => {
        setDate(e);
        setTrigger(trigger + 1)
    }
    const resetSearch = () => {
        setDate();
        setFilter();
        setTrigger(trigger + 1)
        setTrigger2(trigger2 + 1)
    }
    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="bgHeaderC-deepBlue" style={{ height: "max-content" }} >
                <Row className="d-flex px-4 py-3">
                    <Col xs={10}>
                        {/* <img className="pt-1 imgLogoBg" src={Logo} alt="logo" /> */}
                        <img className="" src={Logo} alt="logo" />
                    </Col>
                    {!!permissions?.notifications?.read ?
                        <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                            <Badge color="primary" variant="dot" invisible={counter?.homeReducer?.totalNoRead >= 1 ? false : true}  >
                                <Notifications
                                    sx={{ color: "#0db7e1" }}
                                />
                            </Badge>
                        </Col>
                        : ""}
                    <Col xs={12} className="mt-3">
                        <span className="text-white display-6 fw-bold">{props.info.bank}</span>
                    </Col>
                    <Col xs={12} className="mt-2">
                        <span className="text-white fw-bold" style={{ letterSpacing: "3px" }}>{props.info.ccarNbr}</span>
                    </Col>
                    <Col xs={12} className="mt-2">
                        <span className="text-white fw-bold">{props.info.fullName}</span>
                    </Col>
                    <Col xs={12} className="mt-2">
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={localeMap['es']}
                        >
                            <DatePicker                                
                                views={['year', 'month']}
                                label=""
                                defaultCalendarMonth={hoy}                                
                                defaulValue
                                value={date}
                                onChange={(newValue) => {
                                    searchMonth(newValue?._d)
                                }}
                                renderInput={(params) => <TextField className="selectDate" {...params} sx={{
                                    svg:{color:'#fff'}
                                }}  />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} className="mt-2">
                        <Row className="d-flex">
                            <Col className="text-start">
                                <span
                                    className="fw-bold"
                                    style={{ fontSize: "14px", color: "#2dcb95" }}
                                >
                                    {"$" + numberWithCommas(props.info.quota - props.info.quotaToDate)}
                                </span>
                            </Col>
                            <Col className="text-end">
                                <span
                                    className="text-white fw-bold"
                                    style={{ fontSize: "14px" }}
                                >
                                    {"$" + numberWithCommas(props.info.quota)}
                                </span>
                            </Col>
                        </Row>
                        <LinearProgress
                            className="progress"
                            variant="determinate"
                            value={props.info.percentageConsumed}
                        />
                    </Col>
                </Row>
            </div >
            <div className="pt-3 mb-4 mx-4">
                {/* <div
                    className="cardCredit d-flex p-3 pb-0"
                    style={{ height: "70px", backgroundImage: `url(${clearcard})`, backgroundSize: "cover" }} 
                // style={{
                //     backgroundColor: item.franchise === "Visa" ? "white" :
                //         item.franchise === "Discover" ? "#01A0F6" :
                //             item.franchise === "Master" ? "#005DBF" :
                //                 item.franchise === "Amex" ? "white" : ""
                // }}
                >
                     <img src={Logo} alt="2222" style={{ position: "absolute" }} />
                    <div className="w-100">
                        <p className="nameBank mb-0 pt-0">{props.info.bank}</p>
                        <p className="text-white fw-bold pt-0" style={{ fontSize: "14px", paddingLeft: "15px" }}>{props.info.ccarNbr}</p>
                    </div>
                    <div className="align-self-end pr-3">
                        <p className=""><img src={Master} style={{ width: "60px" }} alt="1" /></p>
                    </div>
                </div>*/}
                <Row className="">
                    <Col>
                        <h1 className="mt-3">Movimientos</h1>
                    </Col>
                    <Col className="text-end">
                        <FormControl className="select-filter" fullWidth>
                            <InputLabel sx={{ fontSize: "14px" }} id="demo-simple-select-label">Filtros</InputLabel>
                            <Select
                                key={trigger2}
                                className="select-filter"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={age}
                                label="Age"
                                onChange={(e) => search(e.target.value)}
                            ><ListSubheader>
                                    Filtrar por:
                                    <a
                                        className="clearFilter"
                                        onClick={() => resetSearch()}>limpiar filtro</a>
                                </ListSubheader>
                                <MenuItem value={"payment"}>Pagos </MenuItem>
                                <MenuItem value="purchase">Compras </MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row className="d-block">
                    {!!permissions?.movs?.read ? renderLastMovesList() : ""}
                </Row>
            </div>
            <Navigation></Navigation>
            <ModalNew
                show={showModal}
                onHide={() => { close() }}
                title={dataModal?.transactionType === "Abono" ? <h1 className="textC-green">Detalle de pago</h1> : "Detalle de compra"}
                size={"400"}
            >
                <Row>

                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            className="w-100"
                            label="Tarjeta utilizada"
                            value={props?.info?.bank + " - " + props?.info?.ccarNbrShort}
                            variant="outlined"
                            disabled
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={`concept-${trigger}`}
                            className="w-100"
                            label="Concepto"
                            value={dataModal?.description}
                            variant="outlined"
                            disabled
                        />
                    </Col>
                    {dataModal.transactionType !== "Abono" ?
                        <Col xs={12} className="px-4 mt-3">
                            <TextField
                                key={trigger}
                                className="w-100"
                                label="Tipo de gasto"
                                value={dataModal?.expenseType}
                                variant="outlined"
                                disabled
                            />
                        </Col> : ""}
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            type="date"
                            className="w-100"
                            label="Fecha"
                            value={dataModal?.date}
                            variant="outlined"
                            disabled
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            className="w-100"
                            label="Valor"
                            value={!!dataModal?.amount ? "$" + numberWithCommas(dataModal?.amount) : ""}
                            variant="outlined"
                            disabled
                        />
                    </Col>
                    {dataModal.transactionType === "Recurrente" ?
                        <Col xs={12} className="px-4 mt-3">
                            <TextField
                                key={trigger}
                                className="w-100"
                                label="Tipo de pago"
                                value={dataModal.transactionType}
                                variant="outlined"
                                disabled
                            />
                        </Col> : ""}
                    {dataModal.transactionType === "Recurrente" ?
                        <Col xs={12} className="px-4 mt-3">
                            <TextField
                                key={trigger}
                                className="w-100"
                                label="Cuotas"
                                value={dataModal.duesQty}
                                variant="outlined"
                                disabled
                            />
                        </Col>
                        : ""}
                    {dataModal.transactionType === "Recurrente" ?
                        <Col xs={12} className="px-4 mt-3 d-flex">
                            <div className="text-start col-6">
                                <span className="textC-gray">
                                    <b>Inicio </b>
                                    {convertDateToLatinFormat2(dataModal?.date)}
                                </span>
                            </div>

                            <div className="text-end col-6">
                                <span className="textC-gray text-end">
                                    <b> Final </b>
                                    {convertDateToLatinFormat2(dataModal?.finalDate)}
                                </span>
                            </div>
                        </Col>
                        : ""}
                    {dataModal?.transactionType !== "Abono" ?
                        <>
                            <Col xs={12} className="px-4 mt-3">
                                <span className="fw-bold textC-deepBlue">Detalle de la factura</span>
                            </Col>
                            <Col xs={3} className="px-4 mt-3">
                            </Col>
                            <Col xs={9} className="px-4 mt-3">
                                <div className="text-end">
                                    <button
                                        className="btnModalGreen "
                                        onClick={()=>handleDownloadFiles()}
                                    // disabled={!!info.pur_justification ? false : true}
                                    >
                                        <Clip sx={{ color: "#fff" }} />
                                        Ver Factura
                                    </button>
                                </div>
                            </Col>
                        </> : ""}
                    <Col xs={3} className="px-4 mt-3">
                    </Col>
                    <Col xs={9} className="px-4 mt-3">
                        <div className="text-end">
                            <button
                                className="btnModalPrimary "
                                onClick={() => setShowModal(false)}
                            // disabled={!!info.pur_justification ? false : true}
                            >
                                Aceptar
                            </button>
                        </div>
                    </Col>
                </Row>

            </ModalNew>
            <Modal
                open={open}
                onClose={() => handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Atras className="textC-deepBlue " onClick={handleClose} sx={{ fontSize: "3rem" }} />

                </Box>
            </Modal>
        </div >
    )
}
export default CardMovement;
