import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import { styled } from "@mui/material/styles";
import "../../index.scss"
import Notifications from '@mui/icons-material/NotificationsRounded';
import Navigation from "../Nav/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Badge from '@mui/material/Badge';
import Logo from "../../assets/img/icons/logoclear.svg"
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { listUser } from "../../actions/genericAction";
import { SET_SELECTED_USER_DETAIL } from "../../actions/actionTypes";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ModalNew from "../Layouts/ModalNew";
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import {
    listCard,
} from "../../actions/homeAction";
import Style from "../Layouts/genericStyles.module.scss";
import clearcard from "../../assets/img/icons/LightBlue.svg";
import Master from "../../assets/img/icons/masterCard.svg";

function Users(props) {
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();
    const history = useHistory();
    // const [trigger, setTrigger] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const permissions = counter?.loginReducer?.user_data?.permissions;
    if (!permissions?.users?.read) {
        history?.push("/home")
    }
    useEffect(() => {
        dispatch(listUser())
    }, []);

    useEffect(() => {
        if (showModal && modalData.usr_id) {
            dispatch(
                listCard(
                    {
                        usr_id: modalData.usr_id
                    }
                )
            );
        }
    }, [showModal]);

    const renderCardList = () => {
        let tempList = [];
        if (Array.isArray(counter?.homeReducer?.card)) {
            counter?.homeReducer?.card.map((item, index) => {
                item.cards.map((x) => {
                    tempList.push(
                        <Row
                            className="mt-4"
                            key={index}
                            onClick={() => {
                                dispatch({
                                    type: SET_SELECTED_USER_DETAIL,
                                    payload: modalData,
                                    card: x,
                                    permissions: counter?.loginReducer?.user_data?.permissions.users,
                                });
                                history?.push("/home/usuarioTransacciones");
                            }}
                        >
                            <Col
                                className={Style.cardCredit}
                                style={{ flex: 10, backgroundImage: `url(${clearcard})`, backgroundSize: "cover" }}
                            >
                                <div style={{ flex: 10 }}>
                                    <p className="nameBank" style={{ margin: 0, padding: 0 }}>{x.bank}</p>
                                    <p className="codeBank" style={{ marginTop: 4, margin: 0, padding: 0 }}>{x.ccarNbr}</p>
                                </div>
                                <div style={{ flex: 1, margin: 0, padding: 0, alignSelf: "center" }}>
                                    <img src={Master} style={{ width: 60 }} alt="1" />
                                </div>
                            </Col>
                        </Row >)
                })
            })
        }
        return tempList;
    }

    let UsersByCard = [];
    let userActive = 0
    if (Array.isArray(counter.genericReducer.ListUser)) {
        counter.genericReducer.ListUser.map((item, index) => {
            if (item.status === "enabled" || item.status === "active") {
                userActive = userActive + 1
            }
            UsersByCard.push(
                <Row className="border border-primary mt-3 mx-1 p-3" style={{ borderRadius: "15px" }} >
                    <Col className="align-self-center p-0" xs={2}>
                        <PersonRoundedIcon sx={{
                            color: item.status === "disabled" ? "#E0554D" : item.color, fontSize: "40px"
                        }} />
                    </Col>
                    <Col xs={8}>
                        <div>
                            <span className="fw-bold textC-deepBlue">
                                {item.usr_names} {item.usr_surnames}
                            </span>
                        </div>
                        <div>
                            <span className=" textC-deepBlue">
                                {item.job_name}
                            </span>
                        </div>
                    </Col>
                    <Col className="align-self-center p-0" xs={2}>
                        <MoreVertRoundedIcon
                            sx={{ fontSize: "40px" }}
                            onClick={
                                () => {
                                    setShowModal(true);
                                    setModalData({
                                        ...item
                                    });
                                }
                            }
                        />
                    </Col>
                </Row>
            )

        })
    }
    const close = () => {
        setShowModal(false);
        setModalData({});
    }
    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="bgHeaderC-deepBlue" >
                <Row className="d-flex px-4 pt-3">
                    <Col xs={10}>
                        {/* <img className="pt-1 imgLogoBg" src={Logo} alt="logo" /> */}
                        <img className="pt-1" src={Logo} alt="logo" />
                    </Col>
                    {!!permissions?.notifications?.read ?
                        <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                            <Badge color="primary" variant="dot" invisible={counter.homeReducer.totalNoRead >= 1 ? false : true}  >
                                <Notifications
                                    sx={{ color: "#0db7e1" }}
                                />
                            </Badge>
                        </Col>
                        : ""}
                    <Row className="d-block">
                        <Col className="mt-5">
                            <span className="text-white fs-1 fw-bold mt-5">Usuarios</span>
                        </Col>
                        <Col className="">
                            <span className="text-white text-conexion">{userActive} usuarios activos</span>
                        </Col>
                    </Row>
                </Row>
            </div >
            <div className="pt-3 mb-4 mx-4">
                {/* <div
                    className="cardCredit d-flex p-3 pb-0"
                    style={{ height: "70px", backgroundImage: `url(${clearcard})`, backgroundSize: "cover" }} 
                // style={{
                //     backgroundColor: item.franchise === "Visa" ? "white" :
                //         item.franchise === "Discover" ? "#01A0F6" :
                //             item.franchise === "Master" ? "#005DBF" :
                //                 item.franchise === "Amex" ? "white" : ""
                // }}
                >
                     <img src={Logo} alt="2222" style={{ position: "absolute" }} />
                    <div className="w-100">
                        <p className="nameBank mb-0 pt-0">{props.info.bank}</p>
                        <p className="text-white fw-bold pt-0" style={{ fontSize: "14px", paddingLeft: "15px" }}>{props.info.ccarNbr}</p>
                    </div>
                    <div className="align-self-end pr-3">
                        <p className=""><img src={Master} style={{ width: "60px" }} alt="1" /></p>
                    </div>
                </div>*/}
                {UsersByCard}
                {!!permissions?.users?.create ?
                    <Col xs={12} className="mt-4">
                        <div >
                            <button className="btnDark80 text-start"
                                onClick={() => history?.push("/home/crearUsuario")}
                            ><AddCircleRoundedIcon sx={{ marginRight: "10px" }} />Agregar usuario</button>
                        </div>
                    </Col>
                    : ""}
            </div>
            <Navigation info={1}></Navigation>
            <ModalNew
                show={showModal}
                onHide={() => { close() }}
                //title={"¿Qué deseas hacer?"}
                title={`${modalData.usr_names}`}
                subtitle={` ${modalData.usr_surnames}`}
                btnYesName={"Agregar"}
                size={"400"}

            >
                <div
                    className="d-flex"
                    style={{ justifyContent: "center" }}
                    onClick={
                        () => {
                            dispatch({
                                type: SET_SELECTED_USER_DETAIL,
                                payload: modalData,
                                permissions: counter?.loginReducer?.user_data?.permissions?.users,
                            });
                            history?.push("/home/usuarioDetalle");
                        }
                    }
                >
                    <PersonRoundedIcon sx={{ color: "#2dcb95" }} />
                    <span className="textC-deepBlue fw-bold">Ver perfil</span>
                </div>

                {/* <Row className="mt-3" style={{ justifyContent: "center" }}>
                    <Col xs={6}
                        className="borderC-blue border-r15 m-1 d-block"
                        style={{ width: "130px", height: "110px" }}
                    // onClick={() => setDetails({ ...details, show: true, info: dataCard })}
                    
                        onClick={
                            ()=>{
                                dispatch({
                                    type: SET_SELECTED_USER_DETAIL,
                                    payload: modalData,
                                });
                                history?.push("/home/usuarioDetalle");
                            }
                        }
                    >
                        <div className="mt-5">
                            <PersonRoundedIcon sx={{ color: "#2dcb95" }} />
                        </div>
                        <div>
                            <span className="textC-deepBlue fw-bold">Ver perfil </span>
                        </div>
                    </Col>
                    <Col xs={6} 
                        className="borderC-blue border-r15 m-1 d-block" 
                        style={{ width: "130px", height: "110px" }}
                        onClick={
                            ()=>{
                                dispatch({
                                    type: SET_SELECTED_USER_DETAIL,
                                    payload: modalData,
                                });
                                history?.push("/home/usuarioTransacciones");
                            }
                        }
                    >
                        <div className="mt-5">
                            <FormatListBulletedRoundedIcon sx={{ color: "#2dcb95" }} />
                        </div>
                        <div>
                            <span className="textC-deepBlue fw-bold">Transacciones</span>
                        </div>
                    </Col>
                </Row> */}


                <div className="d-flex flex-column" style={{ marginTop: 29 }}>
                    <h2 className={Style.sectionTitle} style={{ margin: "16px 0" }}>
                        Tarjetas Asignadas
                    </h2>
                    <Row style={{ overflowY: "auto", maxHeight: 300 }}>
                        <Col className="d-block" style={{ flex: 1 }}>
                            {renderCardList()}
                        </Col>
                    </Row>
                </div>

            </ModalNew>
        </div >
    )
}
export default Users;
