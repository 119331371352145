import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
const HelpDesk = () => {
  const history = useHistory();
  useEffect(
    () => {
      const handleLocationChange = location => {
        const event = new Event("hashchange");
        window.dispatchEvent(event);
      };
      const unlisten = history.listen(handleLocationChange);
      return () => {
        unlisten();
      };
    },
    [history]
  );
  return '';
};

export default HelpDesk;