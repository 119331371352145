import React, { useEffect, useState } from "react";
import "../../index.scss"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Close from "../../assets/img/icons/modalClose.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row } from "react-bootstrap";
import Notifications from '@mui/icons-material/NotificationsRounded';
import Logo from "../../assets/img/icons/logoclear.svg";
import { editNotification, notification, purchaseApproval } from "../../actions/homeAction";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalNew from "../Layouts/ModalNew";

function ListNotification() {
    const counter = useSelector((state) => state);
    const permissions = counter?.loginReducer?.user_data?.permissions;
    const history = useHistory();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [info, setInfo] = useState([]);
    const [trigger, setTrigger] = useState(1);
    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".");
    }
    if (!permissions?.notifications?.read) {
        history?.push("/home")
    }
    const handlePut = (x) => {
        dispatch(editNotification({
            not_id: x.not_id,
            not_read: true
        }, () => (setTrigger(trigger + 1), setInfo([]))))
    }
    useEffect(() => {
        dispatch(notification())
    }, [trigger]);

    const renderNotifi = () => {
        let tempList = [];
        if (Array.isArray(counter?.homeReducer?.notification)) {
            counter?.homeReducer?.notification.map((item, index) => {
                if (item.not_read === 0) {
                    if (item.ntt_value === "purchaseRequest") {
                        tempList.push(
                            <div className="mt-3" key={index + 1}>
                                <Accordion className="mt-3">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div className="d-block col-8 ">
                                            <div>
                                                <span className="fw-bold textC-deepBlue">
                                                    {item.ntt_name}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="textC-gray">
                                                    {item.ban_name} - {item.ccar_nbr}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="fw-bold textC-deepBlue">
                                                    $ {numberWithCommas(item.pur_amount)}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="textC-gray" style={{ fontWeight: "500" }}>
                                                    {item.usr_names} {item.usr_surnames}
                                                </span>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="mt-1 mb-1">
                                            <span className="textC-gray">
                                                {item.pur_date}
                                            </span>
                                        </div>
                                        <div className="mt-1 mb-1">
                                            <span className="textC-gray">
                                                {item.pur_description}
                                            </span>
                                        </div>
                                        <div className="mt-1 mb-1">
                                            <span className="fw-bold textC-deepBlue">
                                                Justificación:{" "}
                                                <span className="fw-normal textC-gray">
                                                    {item.pur_justification}
                                                </span>
                                            </span>
                                        </div>
                                        <div className="mt-1 mb-1">
                                            <span className="fw-bold textC-deepBlue">
                                                {/* $ {numberWithCommas(item.pur_amount)} */}
                                                {item.transactionType}
                                            </span>
                                        </div>
                                        {!!item.pur_dues_qty && item.pur_dues_qty !== 0 && (
                                            <div className="mt-1 mb-1">
                                                Meses:{" "}
                                                <span className="fw-bold textC-deepBlue">
                                                    {/* $ {numberWithCommas(item.pur_amount)} */}
                                                    {item.pur_dues_qty}
                                                </span>
                                            </div>
                                        ) }
                                        <div className="text-end">
                                            <button
                                                type="button"
                                                className="m-2 btn-circle btn-red"
                                                onClick={() => (setInfo({ ...info, item }), setShowModal(true))}
                                            >
                                                <ClearRoundedIcon sx={{ color: "#e0554d" }} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn-circle btn-green"
                                                onClick={() => Approve(item)}
                                            >
                                                <CheckRoundedIcon sx={{ color: "#2dcb95" }} />
                                            </button>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    } else {
                        tempList.push(
                            <div className="mt-3">
                                <Card>
                                    <CardContent>
                                        <div className="d-block col-12 " style={{ margin: "12px 0px" }}>
                                            <div className="d-flex">
                                                <div className="col-11">
                                                    <span className="fw-bold textC-deepBlue">
                                                        {item.ntt_name}
                                                    </span>
                                                </div>
                                                <div className="col-1">
                                                    <img src={Close} alt="96+66" srcset="" onClick={() => handlePut(item)} />
                                                </div>
                                            </div>
                                            <div>
                                                <span className="textC-gray">
                                                    {item.ban_name} - {item.ccar_nbr}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="fw-bold textC-deepBlue">
                                                    $ {numberWithCommas(item.pur_amount)}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="textC-gray" style={{ fontWeight: "500" }}>
                                                    {item.usr_names} {item.usr_surnames}
                                                </span>
                                            </div>
                                            {item.ntt_value === "requestReject" ? <div className="mt-1 mb-1">
                                                <span className="fw-bold textC-deepBlue">
                                                    Justificación:
                                                    <span className="fw-normal textC-gray">
                                                        {" " + item.pur_justification}
                                                    </span>
                                                </span>
                                            </div> : ""}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                    }
                }
            })
        }
        return tempList;
    }

    const Approve = (x) => {
        dispatch(purchaseApproval({
            pur_id: x.pur_id,
            status: "approved",

        }, dispatch(editNotification({
            not_id: x.not_id,
            not_read: true,
            type: "1"
        }, () => (setTrigger(trigger + 1), setInfo([]))))
        ))
    }
    const toRefuse = (x) => {
        dispatch(purchaseApproval({
            pur_id: x.item.pur_id,
            status: "reject",
            pur_justification: !!x.pur_justification ? x.pur_justification : ""
        }, dispatch(editNotification({
            not_id: x.item.not_id,
            not_read: true,
            type: "2"
        }, () => (setTrigger(trigger + 1), setInfo([]), setShowModal(false))))
        ))
    }

    return (
        <div>
            <div className="bgHeaderC-deepBlue" style={{ height: "70px" }} >
                <Row className="d-flex px-4 pt-1">
                    <Col xs={10}>
                        <img className="pt-2" style={{ width: "40px" }} src={Logo} alt="logo" />
                    </Col>
                    <Col className="mt-3" xs={2}>
                        <Notifications
                            sx={{ color: "#0db7e1" }}
                        />
                    </Col>
                </Row>
            </div >
            <div>
                <div className="d-flex p-3" >
                    < span className="material-icons textC-green" onClick={() => history?.push("/home")} style={{ fontSize: "40px" }}>
                        chevron_left
                    </span>
                    <label className="fw-bold" style={{ alignSelf: "center" }}>
                        Notificaciones ({counter?.homeReducer?.totalNoRead})
                    </label>

                </div>
                <div style={{ padding: "0% 9%" }}>
                    {renderNotifi()}

                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ModalNew
                show={showModal}
                onHide={() => { setShowModal(false) }}
                title={"¿Está seguro?"}
                size={"400"}
            >
                <textarea
                    placeholder="Explique la razón del rechazo"
                    className="textAreaModal"
                    onChange={(e) =>
                        setInfo({
                            ...info,
                            pur_justification: e.target.value
                        })
                    }
                >
                </textarea>
                <div className="d-block mt-2">
                    <div className="text-center mb-3">
                        <button className="btnModalPrimary" onClick={() => toRefuse(info)} disabled={!!info.pur_justification ? false : true}>Rechazar</button>
                    </div>
                    <div className="text-center">
                        <button className="btnModalSecondary" >Cancelar</button>
                    </div>
                </div>
            </ModalNew >
        </div >
    )

}
export default ListNotification;
