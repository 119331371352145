import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
// import { styled } from "@mui/material/styles";
import Logo from "../../assets/logo.svg";
import "../../index.scss";
import { loginUser } from "../../actions/loginActions";
import { useHistory } from "react-router";
import { TailSpin } from "react-loader-spinner";
import logoTTN from "../../assets/img/logo_ttn.png";

function Login(props) {
  const counter = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [trigger, setTrigger] = useState(1);
  const eliminarScripts = () => {
    const scripts = document.querySelectorAll(`
    script[src="https://cdn.jsdelivr.net/npm/html2canvas@1.3.2/dist/html2canvas.min.js}"], 
    script[src="https://code.jquery.com/jquery-3.6.0.min.js"],
    script[src="https://ayuda.ttncompany.com/assest/js/vpocket.js"],
    script[src="https://cdn.jsdelivr.net/npm/html2canvas@1.3.2/dist/html2canvas.min.js"],
    script[src="https://cdn.jsdelivr.net/npm/sweetalert2@11"],
    link[href="https://ayuda.ttncompany.com/assest/css/bsop.css"]`);
    scripts.forEach((script) => {
      script.parentNode.removeChild(script);
    });
    const bsopMainElements = document.querySelectorAll('.bsop-main, .bsop-canvas-edit-main,.bsop-main-modal,.bsop-modal-backdrop,.bsop-spinner-main,.bsop-modal-main');
    if (bsopMainElements.length > 0) {
     bsopMainElements.forEach((element) => {
     element.remove();
    });
}
};
eliminarScripts()
  useEffect(() => {
    if (counter?.loginReducer?.Authenticated) {
      history?.push("/home");
    }
  }, [trigger]);

  const [username, setUsername] = useState({
    username: "",
    password: "",
  });

  // const CssTextField = styled(TextField)({
  //     "& label.Mui-focused": {
  //         color: "#808080"
  //     },
  //     "& .MuiInput-underline:after": {
  //         borderBottomColor: "#0DB7E1"
  //     },
  //     "& .MuiOutlinedInput-root": {
  //         "& fieldset": {
  //             borderColor: "#0DB7E1",
  //             borderRadius: "30px",
  //         },
  //         "&.Mui-focused fieldset": {
  //             borderColor: "#0DB7E1"
  //         }
  //     }
  // });
  const submit = () => {
    setLoading(true);
    dispatch(
      loginUser(
        username.username,
        username.password,
        () => (setLoading(false), setTrigger(trigger + 1))
      )
    );    
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      {!!loading ? (
        <div className="loading">
          <TailSpin color="blue" />
        </div>
      ) : (
        ""
      )}
      <div>
        
        <div style={{ padding: "10%" }}>
          <div className="text-center">
            <img src={Logo} alt="1223" />
          </div>

          {/* <CssTextField
                        className="w-100 mt-5"
                        label="Correo electrónico"
                        type="text"
                        id="custom-css-outlined-input"
                        value={username}
                    /> */}
          <TextField
            className="w-100 mt-5"
            label="Correo electrónico"
            type="text"
            id="custom-css-outlined-input"
            onChange={(e) =>
              setUsername({
                ...username,
                username: e.target.value,
              })
            }
          />
          {/* <CssTextField
                        className="w-100 mt-3"
                        label="Contraseña"
                        type="password"
                        id="custom-css-outlined-input"
                        onChange={(e) =>
                            setParams({
                                ...params,
                                password: e.target.value,
                            })}
                    /> */}
          <TextField
            className="w-100 mt-3"
            label="Contraseña"
            type="password"
            id="custom-css-outlined-input"
            onChange={(e) =>
              setUsername({
                ...username,
                password: e.target.value,
              })
            }
          />
          <button className="mt-3 btnDark" onClick={() => submit()}>
            Ingresar
          </button>
          <div className="text-center mt-3">
            <label
              onClick={() => history?.push("/login/olvidarcontrasena")}
              className="hoverPointer"
            >
              ¿Olvidaste tu contraseña?
            </label>
          </div>
        </div>
        <div className="d-flex flex-column my-4 flex-end text-center">
          <p className="text-center">© Todos los derechos reservados</p>
          <img src={logoTTN} alt="TTN" width="70" className="mx-auto" />
        </div>
      </div>
    </div>
  );
}
export default Login;
