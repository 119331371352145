import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Redirect, useLocation, useHistory } from "react-router-dom";
import whiteBack from "../../assets/img/icons/whiteBack.svg";
import Logo from "../../assets/img/icons/logoclear.svg";
import { useSelector } from "react-redux";
import { Badge, Notifications } from "@mui/icons-material";

function SuccessScreen() {
  const counter = useSelector((state) => state);
  const history = useHistory();
  const location = useLocation();
  const permissions = counter?.loginReducer?.user_data?.permissions;
  return !!location.data ? (
    <>
      <div className="bgHeaderC-deepBlue" style={{ height: "70px" }}>
        <Row className="d-flex px-4 pt-1">
          <Col xs={10}>
            <img
              className="pt-2"
              style={{ width: "40px" }}
              src={Logo}
              alt="logo"
            />
          </Col>
          {!!permissions?.notifications?.read ? (
            <Col
              className="bgNotifi"
              xs={2}
              onClick={() => history?.push("/home/notificaciones")}
            >
              <Badge
                color="primary"
                variant="dot"
                invisible={counter.homeReducer.totalNoRead >= 1 ? false : true}
              >
                <Notifications sx={{ color: "#0db7e1" }} />
              </Badge>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
      <div className="container p-3 mt-4">
        <div className="d-flex flex-column justify-content-center mx-auto text-center">
          <h1>{location?.data?.message}</h1>
          <img
            className="mx-auto"
            src={location?.data?.img}
            width="90%"
            alt="Imagen de confirmación"
          />
          <Link
            className="btnDark text-decoration-none w-50 mx-auto"
            to="/home/compras"
          >
            {" "}
            <img
              src={whiteBack}
              alt="regresar"
              width="6"
              className="my-auto align-middle"
            />{" "}
            Regresar
          </Link>
        </div>
      </div>
    </>
  ) : (
    <Redirect to="/home/compras" />
  );
}
export default SuccessScreen;
