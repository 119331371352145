
import { 
    GET_LAST_MOVES, 
    GET_LIST_CARD, 
    GET_NOTIFICATION,
    GET_CARD_PURCHASES,
} from "../actions/actionTypes";

const initialState = {
    card: [],
    lastMoves: [],
    totalCard: 0,
    totalNoRead: 0,

    
    cardTransaction: [],
    cardTransactionTotal: 0,
};
export const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_CARD:
            return {
                ...state,
                card: action.payload,
                totalCard: action.total
            };
        case GET_LAST_MOVES:
            return {
                ...state,
                lastMoves: action.payload,
                totalCard: action.total
            };
        case GET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload,
                totalNoti: action.total,
                totalNoRead: action.totalNoRead
            };
        case GET_CARD_PURCHASES:
            return {
                ...state,
                cardTransaction: action.payload,
                cardTransactionTotal: action.total,
            };
        default:
            return state;
    }
}