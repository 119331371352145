import React, { useEffect, useState } from "react";
import "../../index.scss"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "react-bootstrap";
import Notifications from '@mui/icons-material/NotificationsRounded';
import Switch from '@mui/material/Switch';
import Logo from "../../assets/img/icons/logoclear.svg";
import Master from "../../assets/img/icons/masterCard.svg";
import clearcard from "../../assets/img/icons/LightBlue.svg";
import editBtn from "../../assets/img/icons/editBtn.svg";
import userBG from "../../assets/img/userBG.png";
import { Autocomplete, Checkbox, Select, TextField } from "@mui/material";
import {
    jobPositions,
    roleTypes,
    updateUserInfo,
} from "../../actions/genericAction";
import { Badge } from "@mui/icons-material";
import Style from "../Layouts/genericStyles.module.scss";
import {
    listCard,
} from "../../actions/homeAction";

function UserDetails(props) {
    const counter = useSelector((state) => state);
    const history = useHistory();
    const [trigger, setTrigger] = useState(1);
    const dispatch = useDispatch();
    const [checkedCards, setCheckedCards] = useState([]);
    //const permissions = counter.loginReducer.user_data?.permissions;
    const permissions = counter?.genericReducer?.selectedUserPermission;

    const myData = {
        ...counter.genericReducer.selectedUserDetail,
    }

    const [data, setData] = useState({
        showEdit: false,
        ...myData
    });

    useEffect(() => {
        if (!myData?.usr_id) {
            history?.push("/home/usuarios");
        }
    }, [myData?.usr_id])

    useEffect(() => {
        dispatch(roleTypes());
        dispatch(jobPositions());
        dispatch(
            listCard(
                {
                    usr_id: myData.usr_id
                }
            )
        );
    }, [trigger]);

    useEffect(() => {
        let temparr = []
        if (Array.isArray(counter?.homeReducer?.card)) {
            counter?.homeReducer?.card.map((item, index) => {
                let temparr1 = item.cards.map(item => item.ccar_id);
                temparr.push(...temparr1);
            })
            setCheckedCards(temparr);
        }
    }, [counter?.homeReducer?.totalCard])

    let optionRoleType = [];
    if (Array.isArray(counter.genericReducer.roleTypes)) {
        counter.genericReducer.roleTypes.map((item, index) => {
            optionRoleType.push(
                { label: item.rol_name, value: item.rol_id },
            )
        })
    }
    let optionJobPositions = [];
    if (Array.isArray(counter.genericReducer.jobPositions)) {
        counter.genericReducer.jobPositions.map((item, index) => {
            optionJobPositions.push(
                { label: item.job_name, value: item.job_id },
            )
        })
    }

    const handleCheckBox = (check_id) => {
        let temparray = [...checkedCards];
        let foundindex = temparray.findIndex(x => x == check_id);
        if (foundindex >= 0) {
            temparray.splice(foundindex, 1)
        } else {
            temparray.push(check_id);
        }
        setCheckedCards(temparray);
    }

    const sendEditRequest = () => {
        // let error = "";

        // if(data.showEdit){
        //     if(!data.usr_email){ error = "";}
        // }

        dispatch(
            updateUserInfo({
                ...data,
                //status: data.user_enabled? "enabled" : "disabled",
                ccard_ids: checkedCards,
            })
        )
    }

    const renderCardList = () => {
        let tempList = [];
        if (Array.isArray(counter?.homeReducer?.card)) {
            counter?.homeReducer?.card.map((item, index) => {
                item.cards.map((x) => {
                    tempList.push(
                        <Row className="mt-4" key={index} >
                            <Col
                                className={Style.cardCredit}
                                style={{ flex: 10, backgroundImage: `url(${clearcard})`, backgroundSize: "cover" }}
                            >
                                <div style={{ flex: 10 }}>
                                    <p className="nameBank" style={{ margin: 0, padding: 0 }}>{x.bank}</p>
                                    <p className="codeBank" style={{ marginTop: 4, margin: 0, padding: 0 }}>{x.ccarNbr}</p>
                                </div>
                                <div style={{ flex: 1, margin: 0, padding: 0, alignSelf: "center" }}>
                                    <img src={Master} style={{ width: 60 }} alt="1" />
                                </div>
                            </Col>
                            <Col className="d-flex" style={{ flex: 1 }}>
                                <Checkbox
                                    type="checkbox"
                                    style={{ alignSelf: "center" }}
                                    checked={
                                        Array.isArray(checkedCards) ?
                                            checkedCards.findIndex(chkcard => chkcard == x.ccar_id) >= 0
                                            :
                                            false
                                    }
                                    onChange={() => handleCheckBox(x.ccar_id)}
                                />
                            </Col>
                        </Row >)
                })
            })
        }
        return tempList;
    }

    return (
        <div>
            <div className="bgHeaderC-deepBlue" style={{ height: "70px" }} >
                <Row className="d-flex px-4 pt-1">
                    <Col xs={10}>
                        <img className="pt-2" style={{ width: "40px" }} src={Logo} alt="logo" />
                    </Col>
                    {!!counter?.loginReducer?.user_data?.permissions?.notifications?.read ?
                        <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                            <Badge
                                color="primary"
                                variant="dot"
                            >
                                <Notifications
                                    sx={{ color: "#0db7e1" }}
                                />
                            </Badge>
                        </Col>
                        : ""}
                </Row>
            </div >

            <div
                className={`${Style.userDetailContainer}`}
                style={{ padding: "35px 32px" }}
            >
                <div className={`d-flex p-3`}>
                    < span
                        className="material-icons textC-green"
                        onClick={
                            () => !!props.info? 
                                props?.setDetails({ show: false }) 
                            : 
                                //history?.push("/home/usuarios")
                                history?.goBack()
                            }
                        style={{ fontSize: "40px" }}
                    >
                        chevron_left
                    </span>
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <label className="display-6 fw-bold" style={{ alignSelf: "center" }}>
                            {myData?.usr_names}
                        </label>
                        <p
                            className={Style.headLine5}
                            style={{ alignSelf: "center", margin: "4px  0 0 0" }}
                        >
                            {myData?.job_name}
                        </p>
                        <p
                            className={Style.contextTextRegular}
                            style={{ alignSelf: "center", margin: 0 }}
                        >
                            {myData?.usr_email}
                        </p>
                    </div>
                    {!!permissions?.update ?
                        <span
                            onClick={() => setData({
                                ...data,
                                showEdit: !data.showEdit
                            })}
                        >
                            <img
                                src={editBtn}
                                alt="editar"
                            />
                        </span>
                        : ""}
                </div>

                <Row className={`p-3 ${data.showEdit ? "" : Style.hide}`} >
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            autoComplete='off'
                            className="w-100"
                            label="Correo electrónico*"
                            type="text"
                            id="custom-css-outlined-input"
                            value={data.usr_email}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    usr_email: e.target.value,
                                })}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            autoComplete='off'
                            className="w-100"
                            label="Nombres*"
                            type="text"
                            id="custom-css-outlined-input"
                            value={data.usr_names}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    usr_names: e.target.value,
                                })}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            autoComplete='off'
                            className="w-100"
                            label="Apellidos*"
                            type="text"
                            id="custom-css-outlined-input"
                            value={data.usr_surnames}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    usr_surnames: e.target.value,
                                })}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <Autocomplete
                            key={trigger}
                            disablePortal
                            id="rol_id"
                            options={optionRoleType}
                            renderInput={(params) => <TextField {...params} label="Tipo de usuario*" />}
                            value={optionRoleType.find(op => op.value == data.rol_id)}
                            onChange={(event, newValue) => {
                                setData({
                                    ...data,
                                    rol_id: newValue?.value,
                                });
                            }}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <Autocomplete
                            key={trigger}
                            disablePortal
                            id="job_id"
                            options={optionJobPositions}
                            renderInput={(params) => <TextField {...params} label="Cargo*" />}
                            value={optionJobPositions.find(op => op.value == data.job_id)}
                            onChange={(event, job_id) => {
                                setData({
                                    ...data,
                                    job_id: job_id.value,
                                });
                            }}
                        />
                    </Col>
                </Row>

                <div className="d-flex flex-column">
                    <h2 className={Style.sectionTitle} style={{ margin: "16px 0" }}>
                        Tarjetas Asignadas
                    </h2>
                    <Row style={{ overflowY: "auto", maxHeight: 300 }}>
                        <Col className="d-block" style={{ flex: 1 }}>
                            {renderCardList()}
                        </Col>
                    </Row>
                </div>

                {!!permissions?.delete ?
                    <div className="d-flex">
                        <Switch
                            checked={data.status === "enabled"}
                            onChange={() => setData({
                                ...data,
                                status: (data.status === "enabled") ? "disabled" : "enabled",
                            })}
                            color="primary"
                        />
                        <p className={Style.toggleTitle} style={{ margin: "0 6px", alignSelf: "center" }}>
                            Usuario Habilitado
                        </p>
                    </div>
                    : ""}


                <div className="d-flex text-center">
                    {!!permissions?.update ?
                        <Col xs={12} className="px-4 mt-3">
                            <div >
                                <button
                                    className="w-80 btnModalPrimary"
                                    onClick={() => sendEditRequest()}
                                >
                                    Guardar cambios
                                </button>
                            </div>
                        </Col>
                        : ""}
                </div>

                <div
                    className="d-flex justify-content-center"
                    style={{
                        marginTop: 27
                    }}
                >
                    <img
                        src={userBG}
                        alt=""
                    />
                </div>
            </div>

        </div >
    )

}
export default UserDetails;
