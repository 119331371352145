import {
  GET_APPLICATIONS_APPROVED,
  GET_JOB_POSITIONS,
  GET_LIST_BANK,
  GET_LIST_COMPANIES,
  GET_LIST_USER,
  GET_POSITION_APP,
  GET_ROLE_TYPE,
  GET_STORE_CARD,
  GET_TYPE_EXPENSE,
  GET_TYPE_TRANSACTION,
  GET_USERS_BY_CARD,
  SET_SELECTED_USER_DETAIL,
} from "../actions/actionTypes";

const initialState = {
  ListBank: [],
  totalBank: 0,
  ListUser: [],
  totalUser: 0,
  ListUserByCard: [],
  totalUserByCArd: 0,
  roleTypes: [],
  totalRoleType: 0,
  jobPositions: [],
  totalJobPositions: 0,
  infoStoreCard: {},
  transactionTypes: [],
  expenseTypes: [],

  selectedUserDetail: {},
  selectedUserCard: {},
  selectedUserPermission: {},
  listCompanies: {},
  applicationsApproved: [],
  positionApp: "",
};
export const genericReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_BANK:
      return {
        ...state,
        ListBank: action.payload,
        totalBank: action.total,
      };
    case GET_LIST_USER:
      return {
        ...state,
        ListUser: action.payload,
        totalUser: action.total,
      };
    case GET_USERS_BY_CARD:
      return {
        ...state,
        ListUserByCard: action.payload,
        totalUserByCArd: action.total,
      };
    case GET_ROLE_TYPE:
      return {
        ...state,
        roleTypes: action.payload,
        totalRoleType: action.total,
      };
    case GET_JOB_POSITIONS:
      return {
        ...state,
        jobPositions: action.payload,
        totalJobPositions: action.total,
      };
    case GET_STORE_CARD:
      return {
        ...state,
        infoStoreCard: action.payload,
      };
    case GET_TYPE_TRANSACTION:
      return {
        ...state,
        transactionTypes: action.payload,
      };
    case GET_TYPE_EXPENSE:
      return {
        ...state,
        expenseTypes: action.payload,
      };
    case SET_SELECTED_USER_DETAIL:
      return {
        ...state,
        selectedUserDetail: action.payload,
        selectedUserCard: action.card,
        selectedUserPermission: action.permissions,
      };
    case GET_APPLICATIONS_APPROVED:
      return {
        ...state,
        applicationsApproved: action.payload,
      };
    case GET_POSITION_APP:
      return {
        ...state,
        positionApp: action.payload,
      };
    case GET_LIST_COMPANIES:
      return {
        ...state,
        listCompanies: action.payload,
      };
    default:
      return state;
  }
};
