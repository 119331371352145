import React from "react";
import "../../index.scss"
import Notifications from '@mui/icons-material/NotificationsRounded';
import { Col, Row } from "react-bootstrap";
import Badge from '@mui/material/Badge';
import Logo from "../../assets/img/icons/logoclear.svg"
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import HelpDesk from "../../components/HelpDesk/HelpDesk";
function Header(props) {
    const counter = useSelector((state) => state);
    const history = useHistory();
    const permissions = counter?.loginReducer?.user_data?.permissions;

    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let user = counter?.loginReducer?.user_data;
    let suport = {
        proyecto: 'VPOCKET',
        idusuario: user?.usr_id ?? '',
        rcc: '122',
        nom: (user?.usr_names ?? '') + ' ' + (user?.usr_surnames ?? ''),
        tipdoc: 'CC',
        numdoc: '',
        email: user?.usr_email ?? '',
        tel: ''
      };
    localStorage.setItem('suport', JSON.stringify(suport))
    function diaSemana(x) {
        let date = new Date(x);
        var fechaNum = date.getUTCDate();
        var mes_name = date.getMonth();
        return fechaNum + " de " + meses[mes_name] + " " + date.getUTCFullYear()
    }

    return (
        <div className="bgHeaderC-deepBlue" >
            <Row className="d-flex px-4 pt-3">
                <Col xs={10}>
                    {/* <img className="pt-1 imgLogoBg" src={Logo} alt="logo" /> */}
                    <img className="pt-1" src={Logo} alt="logo" />
                </Col>
                {!!permissions?.notifications?.read ?
                    <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                        <Badge color="primary" variant="dot" invisible={counter?.homeReducer?.totalNoRead >= 1 ? false : true}  >
                            <Notifications
                                sx={{ color: "#0db7e1" }}
                            />
                        </Badge>
                    </Col> : ""}

                <Row className="d-block">
                    <Col className="mt-5">
                        <span className="text-white fs-1 fw-bold mt-5">Hola,</span>
                        <span className="text-white fs-3 fw-bold mt-5"> {counter?.loginReducer?.user_data?.usr_names}</span>
                    </Col>
                    <Col className="">
                        <span className="text-white text-conexion">Último ingreso {diaSemana(counter?.loginReducer?.user_data?.usr_last_login)}</span>
                    </Col>
                </Row>
            </Row>
            <HelpDesk/>
        </div >
    )

}
export default Header;
