import React, { useEffect, useState } from "react";
import "../../index.scss"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "react-bootstrap";
import Notifications from '@mui/icons-material/NotificationsRounded';
import Logo from "../../assets/img/icons/logoclear.svg";
import NumberFormat from "react-number-format";
import Visa from "../../assets/img/icons/visa.svg"
import Master from "../../assets/img/icons/masterCard.svg"
import Discover from "../../assets/img/icons/discover.svg"
import American from "../../assets/img/icons/americanexpress.svg"
import { Autocomplete, TextField } from "@mui/material";
import PropTypes from 'prop-types';
import { createCardCredit, editCreditCard, listBank, listCompanies, listUser } from "../../actions/genericAction";
import { Badge } from "@mui/icons-material";

const NumberFormatCustom1 = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            allowNegative={false}
            id="cost_price"
            prefix="$"
            thousandsGroupStyle="thousand"
            thousandSeparator
            isNumericString
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                        value2: values.formattedValue
                    },
                });
            }}
        />
    );
});
NumberFormatCustom1.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value2: values.value,
                        value: values.formattedValue
                    },
                });
            }}
            format="#### **** **** ####"
            thousandSeparator
            isNumericString
        />
    );
});
NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };
function CreateCardCredit(props) {
    const counter = useSelector((state) => state);
    const history = useHistory();
    const [createCard, setCreateCard] = useState({});
    const [typeCard, setTypeCard] = useState();
    const [changesInput, setChangesInput] = useState();
    const [trigger, settrigger] = useState(1);
    const dispatch = useDispatch();
    const permissions = counter?.loginReducer?.user_data?.permissions;
    useEffect(() => {
        dispatch(listBank())
        dispatch(listUser())
        dispatch(listCompanies())
        // if (!!props.info) {
        //     dispatch(userByCard({ ccar_id: props.info.ccar_id }))
        // }

    }, [trigger]);
    const [values, setValues] = useState({
        users: [],
        ccar_franchise: "",
        ccar_id: props?.info?.ccar_id,
        ccar_quota: props?.info?.quota,
        ccar_quota_todate: props?.info?.quotaToDate
    });
    const [values2, setValues2] = useState({});
    const handleChange = (event) => {
        if (event.target.name === "ccar_nbr") {
            cc_brand_id(event.target.value2)
            setValues2(event.target.value2)
        }
        if (event?.target?.name === "ccar_quota") {
            if (event?.target?.value != props?.info?.quota) {
                setChangesInput(true)
            } else {
                setChangesInput()
            }
        }
        setValues({
            ...values,
            [event.target.name]: event.target.value,
            ccar_franchise: typeCard
        });
    };
    const handleChangeUser = (event) => {
        setChangesInput(true)
        let arr = []
        event.forEach((e, i) => {
            arr.push(e.value)
        })
        setValues({
            ...values,
            users: arr
        })
    };
    function cc_brand_id(cur_val) {
        setCreateCard({
            ...createCard,
            cardNumber: cur_val,
            franchise: typeCard,
        })
        let jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$');
        let amex_regex = new RegExp('^3[47][0-9]{0,}$');
        let diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$');
        let visa_regex = new RegExp('^4[0-9]{0,}$');
        let mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$');
        let maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$');
        let discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
        cur_val = cur_val.replace(/\D/g, '');
        var sel_brand = "unknown";
        if (cur_val.match(jcb_regex)) {
            sel_brand = "jcb";
        } else if (cur_val.match(amex_regex)) {
            sel_brand = "AMERICAN EXPRESS";
        } else if (cur_val.match(diners_regex)) {
            sel_brand = "diners_club";
        } else if (cur_val.match(visa_regex)) {
            sel_brand = "VISA";
        } else if (cur_val.match(mastercard_regex)) {
            sel_brand = "MASTER CARD";
        } else if (cur_val.match(discover_regex)) {
            sel_brand = "DISCOVER";
        } else if (cur_val.match(maestro_regex)) {
            if (cur_val[0] == '5') {
                sel_brand = "MASTER CARD";
            } else {
                sel_brand = "maestro";
            }
        }
        setTypeCard(sel_brand)
        setValues({
            ...values,
            ccar_franchise: sel_brand
        })
        return sel_brand;

    }
    let optionCompanies = [];
    if (Array.isArray(counter?.genericReducer?.listCompanies)) {
        counter?.genericReducer?.listCompanies.map((item) => {
            optionCompanies.push(
                { label: item.comp_tradename, value: item.comp_id },
            )
        })
    }
    let optionBanks = [];
    if (Array.isArray(counter?.genericReducer?.ListBank)) {
        counter?.genericReducer?.ListBank.map((item) => {
            optionBanks.push(
                { label: item.ban_name, value: item.ban_id },
            )
        })
    }
    let optionUsersAdmin = [];
    if (Array.isArray(counter?.genericReducer?.ListUser)) {
        counter?.genericReducer?.ListUser.map((item) => {
            if (item.rol_id === 3) {
                optionUsersAdmin.push(
                    { label: item.usr_names + " " + item.usr_surnames, value: item.usr_id },
                )
            }
        })
    }
    let optionUsersOperate = [];
    const allUSer = () => {
        if (Array.isArray(counter?.genericReducer?.ListUser)) {
            counter?.genericReducer?.ListUser.map((item) => {
                optionUsersOperate.push(
                    { label: item.usr_names + " " + item.usr_surnames, value: item.usr_id },
                )
            })
        }
        return optionUsersOperate
    }
    let optionUsersByCard = [];
    const oneUser = () => {
        if (Array.isArray(counter?.genericReducer?.ListUserByCard)) {
            counter?.genericReducer?.ListUserByCard.map((item) => {
                optionUsersByCard.push(
                    { label: item.usr_names + " " + item.usr_surnames, value: item.usr_id },
                )

            })
        }
        return optionUsersByCard
    }
    return (
        <div>
            <div className="bgHeaderC-deepBlue" style={{ height: "70px" }} >
                <Row className="d-flex px-4 pt-1">
                    <Col xs={10}>
                        <img className="pt-2" style={{ width: "40px" }} src={Logo} alt="logo" />
                    </Col>
                    {!!permissions?.notifications?.read ?
                        <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                            <Badge color="primary" variant="dot" invisible={counter?.homeReducer?.totalNoRead >= 1 ? false : true}  >
                                <Notifications
                                    sx={{ color: "#0db7e1" }}
                                />
                            </Badge>
                        </Col>
                        : ""}
                </Row>
            </div >
            <div>
                <div className="d-flex p-3">
                    < span
                        className="material-icons textC-green"
                        onClick={() => !!props.info ? props?.setDetails({ show: false }) : history?.push("/home")}
                        style={{ fontSize: "40px" }}
                    >
                        chevron_left
                    </span>
                    <label className="display-6 fw-bold" style={{ alignSelf: "center" }}>
                        {!!props.info ? "Editar tarjeta" : "Crear tarjeta"}
                    </label>
                </div>
                <Row className="p-3 d-flex">
                    <Col className="text-center">
                        <div className={typeCard === "AMERICAN EXPRESS" ? "cardTypeSelec" : ""}>
                            <img src={American} alt="American" style={{ width: "50px" }} />
                        </div>
                    </Col>
                    <Col className="text-center">
                        <div className={typeCard === "MASTER CARD" ? "cardTypeSelec" : ""}>
                            <img src={Master} alt="Master" style={{ width: "50px" }} />
                        </div>
                    </Col>
                    <Col className="text-center">
                        <div className={typeCard === "VISA" ? "cardTypeSelec" : ""}>
                            <img src={Visa} alt="visa" style={{ width: "50px" }} />
                        </div>
                    </Col>
                    <Col className="text-center">
                        <div className={typeCard === "DISCOVER" ? "cardTypeSelec" : ""}>
                            <img src={Discover} alt="Discover" style={{ width: "70px" }} />
                        </div>
                    </Col>
                </Row>
                <Row className="p-3">
                    <Col xs={12} className="px-4 mt-3">
                        <Autocomplete
                            key={trigger}
                            disablePortal
                            disabled={!!props.info ? true : false}
                            id="ban_id"
                            value={!!props.info ? props.info.bank : values.ban_id}
                            options={optionBanks}
                            renderInput={(params) => <TextField {...params} label="Banco" />}
                            onChange={(event, newValue) => {
                                setValues({
                                    ...values,
                                    ban_id: newValue?.value,
                                });
                            }}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            autoComplete='off'
                            disabled={!!props.info ? true : false}
                            className="w-100"
                            label="Número de tarjeta"
                            value={!!props.info ? props.info.ccarNbr : values2}
                            onChange={handleChange}
                            name="ccar_nbr"
                            id="ccar_nbr"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            variant="outlined"
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <Autocomplete
                            key={trigger}
                            disablePortal
                            disabled={!!props.info ? true : false}
                            id="usr_id"
                            value={!!props.info ? props.info.compTradename : values.compId}
                            options={optionCompanies}
                            renderInput={(params) => <TextField {...params} label="Cuenta" />}
                            onChange={(event, newValue) => {
                                setValues({
                                    ...values,
                                    compId: newValue?.value,
                                });
                            }}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <span className="textC-deepBlue fs-6 fw-bold">Uso de la tarjeta</span>
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <Autocomplete
                            key={trigger}
                            disablePortal
                            disabled={!!props.info ? true : false}
                            id="usr_id"
                            value={!!props.info ? props.info.fullName : values.usr_id}
                            options={optionUsersAdmin}
                            renderInput={(params) => <TextField {...params} label="Titular de la tarjeta" />}
                            onChange={(event, newValue) => {
                                setValues({
                                    ...values,
                                    usr_id: newValue?.value,
                                });
                            }}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            autoComplete='off'
                            className="w-100"
                            label="Cupo disponible de la tarjeta"
                            onChange={handleChange}
                            value={values.ccar_quota}
                            name="ccar_quota"
                            id="ccar_quota"
                            InputProps={{
                                inputComponent: NumberFormatCustom1,
                            }}
                            variant="outlined"
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            className="w-100"
                            autoComplete='off'
                            disabled={!!props.info ? true : false}
                            label="Cupo disponible a la fecha"
                            value={values.ccar_quota_todate}
                            onChange={handleChange}
                            name="ccar_quota_todate"
                            id="ccar_quota_todate"
                            InputProps={{
                                inputComponent: NumberFormatCustom1,
                            }}
                            variant="outlined"
                        />
                    </Col>
                    {!!props.info ? "" : <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            autoComplete='off'
                            className="w-100"
                            label="Monto mínimo de notificaciones"
                            value={!!props.info ? props.info.minNotAmount : values.ccar_min_not_amount}
                            onChange={handleChange}
                            name="ccar_min_not_amount"
                            id="ccar_min_not_amount"
                            InputProps={{
                                inputComponent: NumberFormatCustom1,
                            }}
                            variant="outlined"
                        />
                    </Col>}
                    <Col xs={12} className="px-4 mt-3">
                        <Autocomplete
                            key={trigger}
                            multiple
                            id="Asignar usuarios"
                            options={allUSer()}
                            getOptionLabel={(option) => option.label}
                            defaultValue={oneUser()}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Asignar usuarios"
                                    placeholder=""
                                />
                            )}
                            onChange={(event, newValue) => {
                                handleChangeUser(newValue);
                            }}
                        />
                    </Col>
                    {!!props.info ? <div className="d-flex"><Col xs={6} className="px-4 mt-3">
                        <div>
                            <button className="w-100 btnModalSecondary" onClick={() => !!props.info ? props?.setDetails({ show: false, showModal: false }) : history?.push("/home")}>Cancelar</button>
                        </div>
                    </Col>
                        <Col xs={6} className="px-4 mt-3">
                            <div >
                                <button className="w-100 btnModalPrimary"
                                    disabled={!!changesInput ? false : true}
                                    onClick={() =>
                                        dispatch(
                                            editCreditCard(
                                                values,
                                                () => {
                                                    setValues({
                                                        users: [],
                                                        ccar_id: !!props.info.ccar_id ? props.info.ccar_id : "",
                                                        ccar_quota: props?.info?.quota,
                                                        ccar_quota_todate: props?.info?.quotaToDate
                                                    }); props?.setDetails({ show: false, showModal: false })
                                                }
                                            )
                                        )
                                    } >Guardar</button>
                            </div>
                        </Col></div> :
                        <Col xs={12} className="px-4 mt-3">
                            <div >
                                <button className="btnDark" onClick={() =>
                                    dispatch(
                                        createCardCredit(
                                            values,
                                            () => (setValues2(), setValues({ users: [] }), settrigger(trigger + 1), setTypeCard())
                                        )
                                    )
                                } >Crear</button>
                            </div>
                        </Col>
                    }

                </Row>
            </div >

        </div >
    )

}
export default CreateCardCredit;
