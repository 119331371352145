export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const GET_LIST_CARD = "GET_LIST_CARD";
export const GET_LAST_MOVES = "GET_LAST_MOVES";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_LIST_BANK = "GET_LIST_BANK";
export const GET_LIST_USER = "GET_LIST_USER";
export const GET_USERS_BY_CARD = "GET_USERS_BY_CARD";
export const LOGOUT = "LOGOUT";
export const GET_ROLE_TYPE = "GET_ROLE_TYPE";
export const GET_JOB_POSITIONS = "GET_JOB_POSITIONS";
export const GET_STORE_CARD = "GET_STORE_CARD";
export const GET_TYPE_TRANSACTION = "GET_TYPE_TRANSACTION";
export const GET_TYPE_EXPENSE = "GET_TYPE_EXPENSE";

export const SET_SELECTED_USER_DETAIL = "SET_SELECTED_USER_DETAIL";
export const GET_CARD_PURCHASES = "GET_CARD_PURCHASES";
export const GET_APPLICATIONS_APPROVED = "GET_APPLICATIONS_APPROVED";
export const GET_POSITION_APP = "GET_POSITION_APP";
export const GET_LIST_COMPANIES = "GET_LIST_COMPANIES";