import React from "react";
// import { styled } from "@mui/material/styles";
import "../../index.scss"
import CssBaseline from '@mui/material/CssBaseline';
import { Backdrop, BottomNavigation, BottomNavigationAction, Paper, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { Box } from "@mui/system";
import Home from '@mui/icons-material/HomeRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Card from '@mui/icons-material/CreditCardRounded';
import { GET_POSITION_APP, GET_STORE_CARD } from "../../actions/actionTypes";
import { Link } from 'react-router-dom';

function Navigation(props) {
    const history = useHistory();
    const [value, setValue] = React.useState(!!props.info ? props.info : 0);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();
    const permissions = counter?.loginReducer?.user_data?.permissions;
    const actions = [];

    if (Array.isArray(counter?.homeReducer?.card)) {
        counter?.homeReducer?.card.forEach((item) => {
            item.cards.forEach((x) => {
                if (x.carStatus !== "disabled") {
                    actions.push(
                        { icon: <Card />, name: (x.bank + " - " + x.ccarNbrShort), info: x },
                    )
                }
            })
        })
    }

    const handleClick = (item) => {
        history?.push("/home/compras");
        dispatch({ type: GET_STORE_CARD, payload: item, });
    };
    const styleRes="max-width: 104px  !important;";
    return (
        <Box sx={{ pb: 7 }} >
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                // onChange={(event, newValue) => {
                //     setValue(newValue);
                // }}
                >

                    <BottomNavigationAction
                      sx={styleRes}  onClick={() =>
                            counter?.genericReducer?.positionApp === "" ? history?.push("/home") :
                                dispatch({
                                    type: GET_POSITION_APP,
                                    payload: "",
                                })}
                        label="Inicio" icon={<Home />} />
                    {!!permissions?.users?.read ?
                        <BottomNavigationAction sx={styleRes} onClick={() => (history?.push("/home/usuarios"),
                            dispatch({
                                type: GET_POSITION_APP,
                                payload: "",
                            })
                        )} label="Usuarios" icon={<GroupRoundedIcon />} />
                        : ""}
                    <BottomNavigationAction sx={styleRes} onClick={() => (
                        history?.push("/home/miCuenta"),
                        dispatch({
                            type: GET_POSITION_APP,
                            payload: "",
                        })
                    )} label="Mi cuenta" icon={<PersonRoundedIcon />} />
                    {!!permissions?.params?.create ?
                        <BottomNavigationAction
                        sx={styleRes} onClick={() => (history?.push("/home/parametrizacion"),
                                dispatch({
                                    type: GET_POSITION_APP,
                                    payload: "",
                                })
                            )}
                            label="Ajustes"
                            icon={<AssignmentIcon />}
                        />
                        :
                        ""
                    }
                    {!permissions?.purchases?.create && !permissions?.payment?.create && !permissions?.request?.create ? "" :
                        < BottomNavigationAction sx={styleRes} />
                    }
                    <Backdrop open={open} />
                    <SpeedDial
                        onOpen={handleOpen}
                        onClose={handleClose}
                        hidden={!permissions?.purchases?.create && !permissions?.payment?.create && !permissions?.request?.create ? true : false}
                        // open={open}
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: 'absolute', bottom: 16, right: 16 }}
                        icon={<SpeedDialIcon />}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                sx={{ width: "max-content" }}
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipOpen
                                onClick={() => handleClick(action.info)}

                            />
                        ))}
                    </SpeedDial>
                </BottomNavigation>
            </Paper>
        </Box>
    )

}
export default Navigation;
