import { URL_VPOCKET, API_VERSION } from "../helpers/constants";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {  USER_LOGIN_SUCCESS } from "./actionTypes";
const MySwal = withReactContent(Swal)
const agregarScript = () => {
    const script1Exists = document.querySelector('script[src^="https://ayuda.ttncompany.com/assest/js/vpocket.js"]');
    const script2Exists = document.querySelector('script[src^="https://code.jquery.com/jquery-3.6.0.min.js"]');
    if (!(script1Exists || script2Exists))
        {
      const script1 = document.createElement('script');
      script1.src = "https://ayuda.ttncompany.com/assest/js/vpocket.js";
      document.body.appendChild(script1);
      const script2 = document.createElement('script');
      script2.src = "https://code.jquery.com/jquery-3.6.0.min.js";
      document.body.appendChild(script2);
        }
  };
export const loginUser = (user, pass, doAfter) => (dispatch, getState) => {
    if (user && pass) {
        var data = {
            email: user,
            password: pass
        }
        fetch(`${URL_VPOCKET}${API_VERSION}login`, {
            method: "POST",
            body: JSON.stringify(data),
            mode: 'cors',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(res => {
                if (res.success && !!res?.results?.Authorization) {
                    dispatch({
                        type: USER_LOGIN_SUCCESS,
                        payload: res.results,
                        Authorization: res.results.Authorization
                    })
                    agregarScript()
                    if (!!doAfter) doAfter();
                } else {
                    doAfter()
                    MySwal.fire({
                        icon: "error",
                        title: "Error ",
                        text: res.message,
                    });
                }
            })
            .catch(() => {
                doAfter()
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al iniciar sesión",
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            });
    }else{
        doAfter()
        MySwal.fire({
            icon:"error",
            title:"Error",
            text:"Usuario o contraseña incorrecta"
        })
    }
}
export const EmailForgotPassword = (data, doAfter) => (dispatch, getState) => {
    fetch(`${URL_VPOCKET}${API_VERSION}forgotPassword`, {
        method: "POST",
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.success) {
                MySwal.fire({
                    icon: "success",
                    title: res.message,
                })
                doAfter()
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
            MySwal.fire({
                icon: "error",
                title: "Error al enviar el correo",
                text: res.message,
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
}
