import React, { useEffect, useState } from "react";
import "../../index.scss"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "react-bootstrap";
import Notifications from '@mui/icons-material/NotificationsRounded';
import Logo from "../../assets/img/icons/logoclear.svg";
import NumberFormat from "react-number-format";
import Visa from "../../assets/img/icons/visa.svg"
import Master from "../../assets/img/icons/masterCard.svg"
import Discover from "../../assets/img/icons/discover.svg"
import American from "../../assets/img/icons/americanexpress.svg"
import { Autocomplete, Select, TextField } from "@mui/material";
import PropTypes from 'prop-types';
import { createCardCredit, createNewUser, jobPositions, listBank, listUser, roleTypes, userByCard } from "../../actions/genericAction";
import { Badge } from "@mui/icons-material";

const NumberFormatCustom1 = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            allowNegative={false}
            id="cost_price"
            prefix="$"
            thousandsGroupStyle="thousand"
            thousandSeparator
            isNumericString
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                        value2: values.formattedValue
                    },
                });
            }}
        />
    );
});
NumberFormatCustom1.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value2: values.value,
                        value: values.formattedValue
                    },
                });
            }}
            format="#### **** **** ####"
            thousandSeparator
            isNumericString
        />
    );
});
NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function CreateNewUser(props) {
    const counter = useSelector((state) => state);
    const history = useHistory();
    const [data, setData] = useState([]);
    const [trigger, settrigger] = useState(1);
    const dispatch = useDispatch();
    const permissions = counter?.loginReducer?.user_data?.permissions;

    useEffect(() => {
        dispatch(roleTypes())
        dispatch(jobPositions())
    }, [trigger]);

    let optionRoleType = [];
    if (Array.isArray(counter?.genericReducer?.roleTypes)) {
        counter?.genericReducer?.roleTypes.map((item, index) => {
            optionRoleType.push(
                { label: item.rol_name, value: item.rol_id },
            )
        })
    }
    let optionJobPositions = [];
    if (Array.isArray(counter?.genericReducer?.jobPositions)) {
        counter?.genericReducer?.jobPositions.map((item, index) => {
            optionJobPositions.push(
                { label: item.job_name, value: item.job_id },
            )
        })
    }

    return (
        <div>
            <div className="bgHeaderC-deepBlue" style={{ height: "70px" }} >
                <Row className="d-flex px-4 pt-1">
                    <Col xs={10}>
                        <img className="pt-2" style={{ width: "40px" }} src={Logo} alt="logo" />
                    </Col>
                    {!!permissions?.notifications?.read ?
                        <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                            <Badge color="primary" variant="dot" invisible={counter.homeReducer.totalNoRead >= 1 ? false : true}  >
                                <Notifications
                                    sx={{ color: "#0db7e1" }}
                                />
                            </Badge>
                        </Col>
                        : ""}
                </Row>
            </div >
            <div>
                <div className="d-flex p-3">
                    < span
                        className="material-icons textC-green"
                        onClick={() => !!props.info ? props?.setDetails({ show: false }) : history?.push("/home/usuarios")}
                        style={{ fontSize: "40px" }}
                    >
                        chevron_left
                    </span>
                    <label className="display-6 fw-bold" style={{ alignSelf: "center" }}>
                        {/* {!!props.info ? "Editar tarjeta" : "Crear tarjeta"} */}
                        Nuevo usuario
                    </label>
                </div>
                <Row className="p-3">
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            autoComplete='off'
                            className="w-100"
                            label="Correo electronico*"
                            type="text"
                            id="custom-css-outlined-input"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    email: e.target.value,
                                })}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            autoComplete='off'
                            className="w-100"
                            label="Nombres*"
                            type="text"
                            id="custom-css-outlined-input"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    names: e.target.value,
                                })}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <TextField
                            key={trigger}
                            autoComplete='off'
                            className="w-100"
                            label="Apellidos*"
                            type="text"
                            id="custom-css-outlined-input"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    surnames: e.target.value,
                                })}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <Autocomplete
                            key={trigger}
                            autoComplete='off'
                            disablePortal
                            id="rol_id"
                            options={optionRoleType}
                            renderInput={(params) => <TextField {...params} label="Tipo de usuario*" />}
                            onChange={(event, newValue) => {
                                setData({
                                    ...data,
                                    rol_id: newValue?.value,
                                });
                            }}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3">
                        <Autocomplete
                            key={trigger}
                            autoComplete='off'
                            disablePortal
                            id="job_id"
                            options={optionJobPositions}
                            renderInput={(params) => <TextField {...params} label="Cargo*" />}
                            onChange={(event, newValue) => {
                                setData({
                                    ...data,
                                    job_id: newValue?.value,
                                });
                            }}
                        />
                    </Col>
                    <div className="d-flex text-center">
                        <Col xs={12} className="px-4 mt-3">
                            <div >
                                <button className="w-80 btnModalPrimary"
                                    onClick={() =>
                                        dispatch(
                                            createNewUser(
                                                data,
                                                () => (settrigger(trigger + 1), setData([]))
                                            )
                                        )
                                    }
                                >Crear usuario</button>
                            </div>
                        </Col>
                    </div>
                </Row>
            </div >

        </div >
    )

}
export default CreateNewUser;
