import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import { styled } from "@mui/material/styles";
import "../../index.scss"
import Notifications from '@mui/icons-material/NotificationsRounded';
import Navigation from "../Nav/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Badge from '@mui/material/Badge';
import Logo from "../../assets/img/icons/logoclear.svg"
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import {
    listUser,
    userByCard,
    editCreditCard,
} from "../../actions/genericAction";
import { SET_SELECTED_USER_DETAIL } from "../../actions/actionTypes";
import clearcard from "../../assets/img/icons/LightBlue.svg";
import Master from "../../assets/img/icons/masterCard.svg";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ModalNew from "../Layouts/ModalNew";
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import { Checkbox } from "@mui/material";
import myStyles from "../Layouts/genericStyles.module.scss";

function ViewUserCard(props) {
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();
    const history = useHistory();
    const [trigger, setTrigger] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const permissions = counter?.loginReducer?.user_data?.permissions;
    if (!permissions?.user_card?.read) {
        history?.push("/home")
    }
    const [otherUserModal, setOtherUserModal] = useState({
        show: false,
    });
    if (counter.genericReducer.positionApp === "") {
        props?.setDetails({ showUser: false })
    }
    const initialUsers = Array.isArray(counter?.genericReducer?.ListUserByCard) ? counter?.genericReducer?.ListUserByCard.map(item2 => item2.usr_id) : [];
    const ownerId = Array.isArray(counter?.genericReducer?.ListUserByCard) ? counter?.genericReducer?.ListUserByCard?.find(item2 => item2?.owner === 1)?.usr_id : 0;
    const [otherUserCheck, setOtherUserCheck] = useState(initialUsers);

    const checkboxToggle = (val) => {
        let tempcheck = [...otherUserCheck];
        if (0 <= tempcheck.findIndex(x => x === val)) {
            tempcheck = tempcheck.filter(x => x != val)
        } else {
            tempcheck.push(val)
        }
        setOtherUserCheck(tempcheck);
    }

    useEffect(() => {
        dispatch(userByCard({ ccar_id: props.info.ccar_id }))
    }, [trigger]);

    useEffect(() => {
        if (otherUserModal.show) {
            dispatch(listUser());
        }
    }, [otherUserModal.show])

    let UsersByCard = [];
    let userActive = 0
    if (Array.isArray(counter?.genericReducer?.ListUserByCard)) {
        counter?.genericReducer?.ListUserByCard.map((item, index) => {
            if (item.userStatus === "enabled") {
                userActive = userActive + 1
            }
            UsersByCard.push(
                <Row className="border border-primary mt-3 mx-1 p-3" style={{ borderRadius: "15px" }} >
                    <Col xs={2}>
                        <PersonRoundedIcon sx={{
                            color: item.status === "disabled" ? "#E0554D" : item.color, fontSize: "40px"
                        }} />
                    </Col>
                    <Col xs={8}>
                        <div>
                            <span className="fw-bold textC-deepBlue">
                                {item.usr_names} {item.usr_surnames}
                            </span>
                        </div>
                        <div>
                            <span className=" textC-deepBlue">
                                {item.job_name}
                            </span>
                        </div>
                    </Col>
                    <Col xs={2}>
                        <MoreVertRoundedIcon
                            onClick={
                                () => {
                                    setShowModal(true);
                                    setModalData({
                                        ...item
                                    });
                                }}
                            sx={{ fontSize: "40px" }}
                        />
                    </Col>
                </Row>
            )

        })
    }

    const close = () => {
        setShowModal(false);
        setModalData({});
    }

    const otherUsers = useMemo(() => {
        if (Array.isArray(counter?.genericReducer?.ListUser && counter?.genericReducer?.ListUserByCard)) {
            return counter?.genericReducer?.ListUser
                //.filter(item => !counter.genericReducer.ListUserByCard.map(item2=>item2.usr_id).includes(item.usr_id)) //to hide already allowed users
                .map((item, index) => {
                    return (
                        <Row
                            key={index}
                            className="border border-primary mt-3 mx-1 p-3"
                            style={{ borderRadius: "15px" }}
                        >
                            <Col xs={2}>
                                <Checkbox
                                    checked={otherUserCheck.find(x => x === item.usr_id)}
                                    onChange={
                                        () =>
                                            (item.rol_name != "Titular") ?
                                                checkboxToggle(item.usr_id)
                                                : null
                                    }
                                    disabled={item.usr_id === ownerId}
                                />
                            </Col>
                            <Col xs={10}>
                                <div>
                                    <span className="fw-bold textC-deepBlue">
                                        {item.usr_names} {item.usr_surnames}
                                    </span>
                                </div>
                                <div>
                                    <span className=" textC-deepBlue">
                                        {item.job_name}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    );
                })
        } else {
            return []
        }

    }, [otherUserModal.show, otherUserCheck.length])

    const sendEdit = () => {

        dispatch(
            editCreditCard(
                {
                    ccar_id: props.info?.ccar_id,
                    users: otherUserCheck,
                },
                () => {
                    setTrigger(trigger + 1);
                    setOtherUserModal({ ...otherUserModal, show: false });
                }
            )
        );

    }

    const renderCardList = () => {
        let tempList = [];
        if (Array.isArray(counter?.homeReducer?.card)) {
            counter?.homeReducer?.card.map((item, index) => {
                item.cards.map((x) => {
                    tempList.push(
                        <Row
                            className="mt-4"
                            key={index}
                            onClick={() => {
                                dispatch({
                                    type: SET_SELECTED_USER_DETAIL,
                                    payload: modalData,
                                    card: x,
                                    permissions: counter?.loginReducer?.user_data?.permissions?.user_card,
                                });
                                history?.push("/home/usuarioTransacciones");
                            }}
                        >
                            <Col
                                className={myStyles.cardCredit}
                                style={{ flex: 10, backgroundImage: `url(${clearcard})`, backgroundSize: "cover" }}
                            >
                                <div style={{ flex: 10 }}>
                                    <p className="nameBank" style={{ margin: 0, padding: 0 }}>{x.bank}</p>
                                    <p className="codeBank" style={{ marginTop: 4, margin: 0, padding: 0 }}>{x.ccarNbr}</p>
                                </div>
                                <div style={{ flex: 1, margin: 0, padding: 0, alignSelf: "center" }}>
                                    <img src={Master} style={{ width: 60 }} alt="1" />
                                </div>
                            </Col>
                        </Row >)
                })
            })
        }
        return tempList;
    }

    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="bgHeaderC-deepBlue" >
                <Row className="d-flex px-4 pt-3">
                    <Col xs={10}>
                        {/* <img className="pt-1 imgLogoBg" src={Logo} alt="logo" /> */}
                        <img className="pt-1" src={Logo} alt="logo" />
                    </Col>
                    {!!permissions?.notifications?.read ?
                        <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                            <Badge color="primary" variant="dot" invisible={counter?.homeReducer?.totalNoRead >= 1 ? false : true}  >
                                <Notifications
                                    sx={{ color: "#0db7e1" }}
                                />
                            </Badge>
                        </Col>
                        : ""}

                    <Row className="d-block">
                        <Col className="mt-5">
                            <span className="text-white fs-1 fw-bold mt-5">Usuarios</span>
                        </Col>
                        <Col className="">
                            <span className="text-white text-conexion">{userActive} usuarios activos</span>
                        </Col>
                    </Row>
                </Row>
            </div >
            <div className="pt-3 mb-4 mx-4">
                <div
                    className="cardCredit d-flex p-3 pb-0"
                    style={{ height: "70px", backgroundImage: `url(${clearcard})`, backgroundSize: "cover" }}
                // style={{
                //     backgroundColor: item.franchise === "Visa" ? "white" :
                //         item.franchise === "Discover" ? "#01A0F6" :
                //             item.franchise === "Master" ? "#005DBF" :
                //                 item.franchise === "Amex" ? "white" : ""
                // }}
                >
                    {/* <img src={Logo} alt="2222" style={{ position: "absolute" }} /> */}
                    <div className="w-100">
                        <p className="nameBank mb-0 pt-0">{props.info.bank}</p>
                        <p className="text-white fw-bold pt-0" style={{ fontSize: "14px", paddingLeft: "15px" }}>{props.info.ccarNbr}</p>
                    </div>
                    <div className="align-self-end pr-3">
                        <p className=""><img src={Master} style={{ width: "60px" }} alt="1" /></p>
                    </div>
                </div>
                {UsersByCard}
                {!!permissions?.user_card?.create ? <Col xs={12} className="mt-4">
                    <div >
                        <button className="btnDark80 text-start"
                            // onClick={() => history?.push("/home")}
                            onClick={() => setOtherUserModal({ ...otherUserModal, show: true })}
                        ><AddCircleRoundedIcon sx={{ marginRight: "10px" }} />Agregar usuario</button>
                    </div>
                </Col> : ""}
            </div>
            <Navigation></Navigation>


            <ModalNew
                show={showModal}
                onHide={() => { close() }}
                //title={"¿Qué deseas hacer?"}
                title={`${modalData.usr_names}`}
                subtitle={` ${modalData.usr_surnames}`}
                btnYesName={"Agregar"}
                size={"400"}
            >
                <div
                    className="d-flex"
                    style={{ justifyContent: "center" }}
                    onClick={
                        () => {
                            dispatch({
                                type: SET_SELECTED_USER_DETAIL,
                                payload: modalData,
                                permissions: counter?.loginReducer?.user_data?.permissions?.user_card,
                            });
                            history?.push("/home/usuarioDetalle");
                        }
                    }
                >
                    <PersonRoundedIcon sx={{ color: "#2dcb95" }} />
                    <span className="textC-deepBlue fw-bold">Ver perfil</span>
                </div>

                <div className="d-flex flex-column" style={{ marginTop: 29 }}>
                    <h2 className={myStyles.sectionTitle} style={{ margin: "16px 0" }}>
                        Tarjetas Asignadas
                    </h2>
                    <Row style={{ overflowY: "auto", maxHeight: 300 }}>
                        <Col className="d-block" style={{ flex: 1 }}>
                            {renderCardList()}
                        </Col>
                    </Row>
                </div>
            </ModalNew>

            {/* <ModalNew
                show={showModal}
                onHide={() => { close() }}
                title={"¿Qué deseas hacer?"}
                btnYesName={"Agregar"}
                size={"400"}

            >
                <div>

                </div>
                <Row className="mt-3" style={{ justifyContent: "center" }}>
                    <Col xs={6}
                        className="borderC-blue border-r15 m-1 d-block"
                        style={{ width: "130px", height: "110px" }}
                    // onClick={() => setDetails({ ...details, show: true, info: dataCard })}
                    >
                        <div className="mt-5">
                            <PersonRoundedIcon sx={{ color: "#2dcb95" }} />
                        </div>
                        <div>
                            <span className="textC-deepBlue fw-bold">Ver perfil </span>
                        </div>
                    </Col>
                    <Col xs={6} className="borderC-blue border-r15 m-1 d-block" style={{ width: "130px", height: "110px" }}>
                        <div className="mt-5">
                            <FormatListBulletedRoundedIcon sx={{ color: "#2dcb95" }} />
                        </div>
                        <div>
                            <span className="textC-deepBlue fw-bold">Transacciones</span>
                        </div>
                    </Col>
                </Row>

            </ModalNew> */}

            <ModalNew
                show={otherUserModal.show}
                onHide={() => setOtherUserModal({ ...otherUserModal, show: false })}
                title={"Usuarios de otras tarjetas"}
                size={"400"}
            >
                <div>
                    {otherUsers}
                </div>
                <div className="d-flex flex-wrap" style={{ marginTop: 16 }} >

                    <button className="btnDark80 text-start" style={{ flex: "0 0 100%", marginBottom: 16 }} >
                        <AddCircleRoundedIcon sx={{ marginRight: "10px" }} />
                        Invitar nuevo usuario
                    </button>
                    <button
                        className={`${myStyles.btnSecondary} ${myStyles.flexWrap48}`}
                        style={{ marginBottom: 16 }}
                        onClick={() => sendEdit()}
                    >
                        Aceptar
                    </button>
                    <div className={myStyles.flexWrap4}></div>
                    <button
                        className={`${myStyles.btnTertiary} ${myStyles.flexWrap48}`}
                        style={{ marginBottom: 16 }}
                        onClick={() => setOtherUserModal({ ...otherUserModal, show: false })}
                    >
                        Cancelar
                    </button>
                </div>
            </ModalNew>
        </div >
    )
}
export default ViewUserCard;
