
import { USER_LOGIN_SUCCESS, LOGOUT } from "../actions/actionTypes";

const initialState = {
    user_data: [],
};
export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                user_data: action.payload,
                Authenticated: true,
                Authorization: action.Authorization
            };
        case LOGOUT:
            return {
                ...state,
                user_data: [],
                Authenticated: false,
                Authorization: ""
            };
        default:
            return state;
    }
}