import React, { useEffect, useState, useCallback } from "react";
import "../../index.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "react-bootstrap";
import Notifications from "@mui/icons-material/NotificationsRounded";
import Logo from "../../assets/img/icons/logoclear.svg";
import NumberFormat from "react-number-format";
import Master from "../../assets/img/icons/masterCard.svg";
import paymentMobile from "../../assets/img/payment-mobile.png";
import { Autocomplete, TextField } from "@mui/material";
import clearcard from "../../assets/img/icons/LightBlue.svg";
import PropTypes from "prop-types";
import {
  transactionTypes,
  expenseTypes,
  createPurchase,
  createPayments,
  applicationsApproved,
} from "../../actions/genericAction";
import { Badge } from "@mui/icons-material";
import CardCheck from "@mui/icons-material/CreditScoreRounded";
import Clip from "@mui/icons-material/AttachFileRounded";
import CamPlus from "@mui/icons-material/AddAPhotoRounded";
import { convertBase64, todayDateFormat } from "../../helpers/helpers";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TailSpin } from "react-loader-spinner";
const NumberFormatCustom1 = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      allowNegative={false}
      id="cost_price"
      prefix="$"
      thousandsGroupStyle="thousand"
      thousandSeparator
      isNumericString
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
            value2: values.formattedValue,
          },
        });
      }}
    />
  );
});
NumberFormatCustom1.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function PurchasesMovs(props) {
  const counter = useSelector((state) => state);
  const userData = counter?.loginReducer?.user_data;
  const permissions = counter?.loginReducer?.user_data?.permissions;
  const history = useHistory();
  const [data, setData] = useState({
    pur_date: todayDateFormat(),
    ccarId: counter.genericReducer.infoStoreCard.ccar_id,
    putId: !!permissions?.request?.create
      ? "2"
      : !!permissions?.purchases?.create
      ? "1"
      : "3",
    purId: null,
    purDescription: "",
    purFiles: [],
  });
  const [name, setName] = useState({
    ccarId: counter.genericReducer.infoStoreCard.ccar_id,
    putId: !!permissions?.request?.create
      ? "2"
      : !!permissions?.purchases?.create
      ? "1"
      : "3",
  });
  const [trigger, settrigger] = useState(1);
  const [trigger2, settrigger2] = useState(1);
  const [update, setUpdate] = useState("0");
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState("")
  const dispatch = useDispatch();

  if (
    !permissions?.purchases?.create &&
    !permissions?.payment?.create &&
    !permissions?.request?.create
  ) {
    history?.push("/home");
  }
  useEffect(() => {
    dispatch(transactionTypes());
    dispatch(expenseTypes());
    dispatch(
      applicationsApproved({
        status: "approved",
        put_id: "2",
        usr_id: counter.loginReducer.user_data.usr_id,
        ccar_id: name.ccarId,
        noRequest: true,
      })
    );
    //eslint-disable-next-line
  }, [trigger2]);

  // pur files - invoice detail
  useEffect(() => {
    setTimeout(() => {
      setInvoiceDetails(renderInvoiceDetail())
    }, 250);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[update,data,trigger,trigger2])
  
  
  const onChangeDoc = (e, type) => {
    let files = e.target.files;
    let purFiles = [];
    if (files.length > 0) {
      Array.from(files).forEach(async (f) => {
        let name_doc = f.name.split(".")[0];
        let type_doc = f.type;
        let encode = await convertBase64(f);
        let file64 = encode.split(",")[1];
        let ext = type_doc.split("/");
        let finalName = `${name_doc}.${ext[1]}`;
        purFiles.push({
          purFileBase64: file64,
          purFilename: finalName,
        });
      });
      setUpdate(type);
    }
    settrigger(trigger + 1)
    setData({ ...data, purFiles })
  };

  let optionApplicationsApproved = [];
  if (Array.isArray(counter.genericReducer.applicationsApproved)) {
    counter.genericReducer.applicationsApproved.forEach((item) => {
      optionApplicationsApproved.push({
        label: item.pur_description + " - " + item.pur_amount,
        value: item,
      });
    });
  }
  let optionExpenseTypes = [];
  if (Array.isArray(counter.genericReducer.expenseTypes)) {
    counter.genericReducer.expenseTypes.forEach((item) => {
      optionExpenseTypes.push({ label: item.ext_name, value: item.ext_id });
    });
  }
  let optionTransactionTypes = [];
  if (Array.isArray(counter.genericReducer.transactionTypes)) {
    counter.genericReducer.transactionTypes.forEach((item) => {
      if (
        data.putId === "1" &&
        counter.loginReducer.user_data.roleValue === "owner"
      ) {
        optionTransactionTypes.push({
          label: item.trt_name,
          value: item.trt_id,
        });
      } else {
        if (data.putId === "2" && item.trt_id !== 1) {
          optionTransactionTypes.push({
            label: item.trt_name,
            value: item.trt_id,
          });
        } else if (data.putId === "1" && item.trt_id === 1) {
          optionTransactionTypes.push({
            label: item.trt_name,
            value: item.trt_id,
          });
        }
      }
      // else {
      //     optionTransactionTypes.push(
      //         { label: item.trt_name, value: item.trt_id },
      //     )
      // }
    });
  }

  const resetForm = () => {
    setUpdate("0");
    setName({});
    setData({
      pur_date: todayDateFormat(),
      ccarId: counter.genericReducer.infoStoreCard.ccar_id,
      putId: "2",
      purFiles: [],
    });
    settrigger(trigger + 1);
    settrigger2(trigger2 + 1);
  };

  const resetFile = () => {
    setUpdate("0");
    setData({
      ...data,
      purFiles: [],
    });
  };
  function dispositivo() {
    let e = navigator.userAgent;
    return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(e)
      ? "tablet"
      : /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          e
        )
      ? "mobile"
      : "desktop";
  }

  function verifyMe() {
    let device = dispositivo();
    return device === "tablet" || device === "mobile" ? true : false;
  }
  const insertData = (event, value) => {
    if (!!event && value) {
      setName({
        ...name,
        ext_name: value.ext_name,
        trt_name: value.trt_name,
        pur_description: value.pur_description,
      });
      setData({
        ...data,
        purDescription: value?.pur_description,
        extId: value?.ext_id,
        purAmount: value?.pur_amount,
        trtId: value?.trt_id,
        purId: value?.pur_id,
        pur_request: value?.pur_id,
        purDuesQty: value?.pur_dues_qty,
      });
      settrigger(trigger + 1);
    } else {
      setData({
        ...data,
        purId: null,
      });
    }
  };
  function clamp(val, min, max) {
    return Math.min(Math.max(val, min), max);
  }
  const handleImagePick = (e, type) => {
    let files = e.target.files;
    let purFiles = [];
    if (files.length > 0) {
      Array.from(files).forEach(async (f) => {
        let name_doc = f.name.split(".")[0];
        let type_doc = f.type;
        let ext = type_doc.split("/");
        let finalName = `${name_doc}.${ext[1]}`;
        const blobURL = window.URL.createObjectURL(f);
        const img = new Image();
        img.src = blobURL;
        img.onload = function (ev) {
          window.URL.revokeObjectURL(blobURL); // release memory
          // Use the img
          const canvas = document.createElement("canvas");
          let newWidth = 1080;
          let newHeight = 720;
          if (img.width >= img.height) {
            newWidth = clamp(img.width, 480, 720);
            newHeight = (img.height * newWidth) / img.width;
          } else {
            newHeight = clamp(img.height, 480, 720);
            newWidth = (img.width * newHeight) / img.height;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          canvas.toBlob(
            function (blob) {
              // Handle the compressed image
              //let encode = convertBase64(blob);
              let encode = canvas.toDataURL("image/png", 0.9);
              let file64 = encode.split(";base64,")[1]; // Delete base64 prefix (data:application...)
              purFiles.push({
                purFileBase64: file64,
                purFilename: finalName,
              });
              // setPutState({
              //     ...putState,
              //     file: blob,
              //     file64: file64,
              //     fileUrl:URL.createObjectURL(blob),
              //   });
            },
            "image/jpeg",
            0.9
          );
        };
        setUpdate(type);
        return setData({
          ...data,
          purFiles,
        });
      });
    }
  };

  const renderInvoiceDetail = () => {
    if (data.putId === "1") {
      return (
        <div>
          <Col xs={12} className="px-4 mt-3">
            <span className="textC-lightBlue fs-6 fw-bold">
              Detalle de la factura
            </span>
          </Col>
          <div className="d-flex">
            <Col xs={6} className="px-2 mt-3">
              <div
                className="w-100"
                style={{
                  marginLeft: "5px",
                  backgroundColor: data.purFiles?.length > 0 && update === "1" ? "#2DCB95" : "",
                  padding: data.purFiles?.length > 0 && update === "1" ? "5px" : "",
                  borderRadius: data.purFiles?.length > 0 && update === "1" ? "20px" : "",
                }}
              >
                <Clip
                  className="hoverPointer"
                  sx={{
                    color:
                      !!data.purFiles?.length > 0 && update === "1"
                        ? "#fff"
                        : "#2DCB95 ",
                  }}
                />
                <label
                  htmlFor="invoice"
                  className={`hoverPointer ${
                    !!data.purFiles?.length > 0 && update === "1"
                      ? "text-white"
                      : "textC-gray"
                  } position-absolute`}
                  style={{ marginTop: "5px" }}
                >
                  {(update === "1" && data.purFiles?.length > 0) ? `Adjuntos (${data.purFiles?.length})` : "Adjuntar factura"}
                  {!!data.purFiles?.length > 0 && update === "1" && (
                    <span className="fw-bold" onClick={() => resetFile()}>
                      {" "}x
                    </span>
                  )}
                </label>
                <input
                  name="invoice"
                  type="file"
                  multiple
                  accept="image/x-png,image/gif,image/jpeg,application/pdf"
                  onChange={(e) => onChangeDoc(e, "1")}
                />
              </div>
            </Col>
            <Col xs={6} className="px-3 mt-3">
              <div
                className="w-100"
                style={{
                  marginLeft: "5px",
                  backgroundColor:
                    !!data.purFiles?.length > 0 && update === "2"
                      ? "#2DCB95"
                      : "",
                  padding:
                    !!data.purFiles?.length > 0 && update === "2" ? "5px" : "",
                  borderRadius:
                    !!data.purFiles?.length > 0 && update === "2" ? "20px" : ""
                }}
              >
                <CamPlus
                  sx={{
                    marginRight:"5px",
                    color:
                      !!data.purFiles?.length > 0 && update === "2"
                        ? "#fff"
                        : "#2DCB95 ",
                  }}
                />
                <label
                  htmlFor="invoicePhoto"
                  className={`${
                    !!data.purFiles?.length > 0 && update === "2"
                      ? "text-white"
                      : "textC-gray"
                  } position-absolute`}
                  style={{ marginTop: "5px" }}
                >
                  {update === "2" && data.purFiles?.length > 0 ? ` Fotos (${data.purFiles?.length})` : "Tomar foto"}
                  {!!data.purFiles?.length > 0 && update === "2" && (
                    <span className="fw-bold" onClick={() => resetFile()}>
                      {" "}x
                    </span>
                  )}
                </label>
                <input
                  name="invoicePhoto"
                  type="file"
                  accept="image/*"
                  multiple
                  // onChange={(e) => onChangeDoc(e, "2")} />
                  onChange={(e) => handleImagePick(e, "2")}
                />
              </div>
            </Col>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      {!!loading && (
        <div className="loading">
          <TailSpin color="blue" />
        </div>
      )}
      <div className="bgHeaderC-deepBlue" style={{ height: "70px" }}>
        <Row className="d-flex px-4 pt-1">
          <Col xs={10}>
            <img
              className="pt-2"
              style={{ width: "40px" }}
              src={Logo}
              alt="logo"
            />
          </Col>
          {!!permissions?.notifications?.read ? (
            <Col
              className="bgNotifi"
              xs={2}
              onClick={() => history?.push("/home/notificaciones")}
            >
              <Badge
                color="primary"
                variant="dot"
                invisible={counter.homeReducer.totalNoRead >= 1 ? false : true}
              >
                <Notifications sx={{ color: "#0db7e1" }} />
              </Badge>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
      <div>
        <div className="d-flex p-3">
          {/* <Atras /> */}
          <span
            className="material-icons textC-green hoverPointer"
            onClick={() =>
              !!props.info
                ? props?.setDetails({ show: false })
                : history?.push("/home")
            }
            style={{ fontSize: "40px" }}
          >
            chevron_left
          </span>
          <label className="display-6 fw-bold" style={{ alignSelf: "center" }}>
            {userData.roleValue === "accountant"
              ? `${counter?.genericReducer?.infoStoreCard?.bank} - ${counter?.genericReducer?.infoStoreCard.ccarNbrShort}`
              : "Nueva compra"}
          </label>
        </div>
        <Row className="p-3">
          {!!permissions?.request?.create ? (
            <Col
              // xs={6}
              className="mt-3"
              style={{ paddingLeft: "1.5rem" }}
              onClick={() => {
                setName({});
                setData({
                  pur_date: todayDateFormat(),
                  ccarId: counter.genericReducer.infoStoreCard.ccar_id,
                  putId: "2",
                });
                settrigger(trigger + 1);
              }}
            >
              <div
                className="borderC-blue border-r15 d-block px-4 py-3"
                style={{ backgroundColor: data.putId === "2" ? "#032233" : "" }}
              >
                <div className="bginputradio text-end">
                  <input
                    type="radio"
                    name="type"
                    value="2"
                    checked={data.putId === "2" ? true : false}
                    onChange={(e) => {
                      setName({});
                      setData({
                        pur_date: todayDateFormat(),
                        ccarId: counter.genericReducer.infoStoreCard.ccar_id,
                        putId: "2",
                        purFiles:[]
                      });
                      settrigger(trigger + 1);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <CardCheck sx={{ color: "#2dcb95" }} />
                </div>
                <div>
                  <span
                    className={`${
                      data.putId === "2" ? "textC-green" : "textC-DeepBlue"
                    }  fw-bold`}
                    style={{ fontSize: "14px" }}
                  >
                    Solicitar compra
                  </span>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}
          {!!permissions?.purchases?.create ? (
            <Col
              // xs={6}
              className="mt-3"
              style={{ paddingRight: "1.5rem" }}
              onClick={() => {
                setName({});
                setData({
                  ...data,
                  ccarId: counter.genericReducer.infoStoreCard.ccar_id,
                  putId: "1",
                });
                settrigger(trigger + 1);
              }}
            >
              <div
                className="borderC-blue border-r15 d-block px-4 py-3"
                style={{ backgroundColor: data.putId === "1" ? "#032233" : "" }}
              >
                <div className="text-end">
                  <input
                    type="radio"
                    name="type"
                    value="2"
                    checked={data.putId === "1" ? true : false}
                    onChange={(e) => {
                      setName({});
                      setData({
                        pur_date: todayDateFormat(),
                        ccarId: counter.genericReducer.infoStoreCard.ccar_id,
                        putId: "1",
                        purFiles:[]
                      });
                      settrigger(trigger + 1);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <CardCheck sx={{ color: "#2dcb95" }} />
                </div>
                <div>
                  <span
                    className={`${
                      data.putId === "1" ? "textC-green" : "textC-DeepBlue"
                    }  fw-bold`}
                    style={{ fontSize: "14px" }}
                  >
                    Registrar compra
                  </span>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}
          {!verifyMe() && !!permissions?.payment?.create ? (
            <Col
              // xs={6}
              className="mt-3"
              style={{ paddingRight: "1.5rem" }}
              onClick={() => {
                setName({});
                setData({
                  ccarId: counter.genericReducer.infoStoreCard.ccar_id,
                  putId: "3",
                  purFiles:[]
                });
                settrigger(trigger + 1);
              }}
            >
              <div
                className="borderC-blue border-r15 d-block px-4 py-3"
                style={{ backgroundColor: data.putId === "3" ? "#2dcb95" : "" }}
              >
                <div className="text-end">
                  <input
                    type="radio"
                    name="type"
                    value="3"
                    checked={data.putId === "3" ? true : false}
                    onChange={(e) => {
                      setData({ ...data, putId: "3" });
                      setName({});
                      setData({
                        ...data,
                        ccarId: counter.genericReducer.infoStoreCard.ccar_id,
                        putId: "3",
                      });
                      settrigger(trigger + 1);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <CardCheck
                    className={`${
                      data.putId === "3" ? "textC-DeepBlue" : "textC-green"
                    }  fw-bold`}
                  />
                </div>
                <div>
                  <span
                    className={`textC-DeepBlue  fw-bold`}
                    style={{ fontSize: "14px" }}
                  >
                    Registrar pago
                  </span>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}
          <Col xs={12} className="px-4 mt-3">
              <div
                className="cardCredit d-flex p-3 pb-0"
                style={{
                  cursor: "auto",
                  maxHeight: "140px",
                  backgroundImage: `url(${clearcard})`,
                  backgroundSize: "cover",
                }}
                // style={{
                //     backgroundColor: item.franchise === "Visa" ? "white" :
                //         item.franchise === "Discover" ? "#01A0F6" :
                //             item.franchise === "Master" ? "#005DBF" :
                //                 item.franchise === "Amex" ? "white" : ""
                // }}
              >
                {/* <img src={Logo} alt="2222" style={{ position: "absolute" }} /> */}
                <div className="w-100">
                  <p className="nameBank mb-0 pt-0">
                    {counter.genericReducer.infoStoreCard.bank}
                  </p>
                  <p
                    className="text-white fw-bold pt-0"
                    style={{
                      fontSize: "16px",
                      paddingLeft: "15px",
                      letterSpacing: "3px",
                    }}
                  >
                    {counter.genericReducer.infoStoreCard.ccarNbr}
                  </p>
                  <p className="nameBank mb-0 pt-0">
                    {counter.genericReducer?.infoStoreCard?.compTradename}
                  </p>
                </div>
                <div className="align-self-end pr-3">
                  <p className="">
                    <img src={Master} style={{ width: "60px" }} alt="1" />
                  </p>
                </div>
              </div>
            </Col>
          <Col xs={12} className="px-4 mt-3">
            <TextField
              key={`card-${trigger}`}
              className="w-100 mt-3"
              label="Tarjeta"
              type="text"
              id="custom-css-outlined-input"
              disabled
              value={counter.genericReducer.infoStoreCard.bank}
            />
          </Col>          
            
          
          {data.putId === "1" ? (
            <Col xs={12} className="px-4 mt-3">
              <Autocomplete
                key={`pur_id-${trigger}`}
                disablePortal
                id="pur_id"
                value={name.pur_description}
                options={optionApplicationsApproved}
                renderInput={(params) => (
                  <TextField {...params} label="Solicitudes aprobadas" />
                )}
                onChange={(event, newValue) => {
                  insertData(event, newValue?.value);
                }}
              />
            </Col>
          ) : (
            ""
          )}

          <Col xs={12} className="px-4 mt-3">
            <TextField
              key={`date-${trigger}`}
              disabled={data.putId === "2" ? true : false}
              className="w-100 mt-3"
              id="date"
              label="Fecha"
              type="date"
              value={data.pur_date}
              onChange={(e) =>
                setData({
                  ...data,
                  pur_date: e.target.value,
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>

          <Col xs={12} className="px-4 mt-3">
            <TextField
              key={`concept1-${trigger}`}
              autoComplete="off"
              disabled={!!data.purId ? true : false}
              className="w-100 mt-3"
              label="Concepto*"
              type="text"
              value={data?.purDescription}
              variant="outlined"
              onChange={(e) => {
                setData({
                  ...data,
                  purDescription: e.target.value,
                });
              }}
            />
          </Col>

          {data.putId === "3" ? (
            ""
          ) : !!data.purId ? (
            <Col xs={12} className="px-4 mt-3">
              <TextField
                autoComplete="off"
                className="w-100"
                disabled
                value={name.ext_name}
                id="outlined-basic"
                label="Tipo de gasto"
                variant="outlined"
              />
            </Col>
          ) : (
            <Col xs={12} className="px-4 mt-3">
              <Autocomplete
                key={`extId-${trigger}`}
                disablePortal
                disabled={!!data.purId ? true : false}
                id="extId"
                value={data.extId}
                options={optionExpenseTypes}
                renderInput={(params) => (
                  <TextField {...params} label="Tipo de gasto*" />
                )}
                onChange={(event, newValue) => {
                  setData({
                    ...data,
                    extId: newValue?.value,
                  });
                }}
              />
            </Col>
          )}
          <Col xs={12} className="px-4 mt-3">
            <TextField
              disabled={!!data.purId ? true : false}
              autoComplete="off"
              key={`value-${trigger}`}
              className="w-100"
              label="Valor*"
              value={data.purAmount}
              onChange={(e) => {
                setData({
                  ...data,
                  purAmount: e.target.value,
                });
              }}
              name="ccar_quota"
              id="ccar_quota"
              InputProps={{
                inputComponent: NumberFormatCustom1,
              }}
              variant="outlined"
            />
          </Col>
          {data.putId === "2" ? (
            <Col xs={12} className="px-4 mt-3">
              <TextField
                autoComplete="off"
                key={`justification-${trigger}`}
                className="w-100 mt-3"
                label="Justificación*"
                type="text"
                value={data.purJustification}
                id="custom-css-outlined-input"
                onChange={(e) => {
                  setData({
                    ...data,
                    purJustification: e.target.value,
                  });
                }}
              />
            </Col>
          ) : (
            ""
          )}
          {data.putId === "3" ? (
            ""
          ) : !!data.purId ? (
            <Col xs={12} className="px-4 mt-3">
              <TextField
                autoComplete="off"
                className="w-100"
                disabled
                id="outlined-basic"
                value={name.trt_name}
                label="Tipo de transacción"
                variant="outlined"
              />
            </Col>
          ) : (
            <Col xs={12} className="px-4 mt-3">
              <Autocomplete
                autoComplete="off"
                disabled={!!data.purId ? true : false}
                key={`usr_id-${trigger}`}
                disablePortal
                value={data.trtId}
                id="usr_id"
                options={optionTransactionTypes}
                renderInput={(params) => (
                  <TextField {...params} label="Tipo de transacción*" />
                )}
                onChange={(event, newValue) => {
                  setData({
                    ...data,
                    trtId: newValue?.value,
                  });
                }}
              />
            </Col>
          )}
          {data.trtId === 2 && (
            <Col xs={12} className="px-4 mt-3">
              <TextField
                key={`quota-${trigger}`}
                disabled={!!data.purId ? true : false}
                className="w-100 mt-3"
                label="No. Meses*"
                value={data.purDuesQty}
                type="text"
                id="custom-css-outlined-input"
                onChange={(e) => {
                  setData({
                    ...data,
                    purDuesQty: e.target.value,
                  });
                }}
              />
            </Col>
          )}
          {/* ANCHOR Detalle de factura */}
          {invoiceDetails}
          <div className="d-flex">
            <Col xs={6} className="px-4 mt-3">
              <div>
                <button
                  className="w-100 btnModalSecondary"
                  onClick={() => {
                    resetForm();
                  }}
                >
                  Cancelar
                </button>
              </div>
            </Col>
            <Col xs={6} className="px-4 mt-3">
              <div>
                <button
                  className="w-100 btnModalPrimary"
                  onClick={() =>
                    data.putId === "3"
                      ? (setLoading(true),
                        dispatch(
                          createPayments(
                            {
                              ccar_id:
                                counter.genericReducer.infoStoreCard.ccar_id,
                              pay_description: data.purDescription,
                              pay_date: data.pur_date,
                              pay_value: data.purAmount,
                            },
                            (value) =>
                              !!value
                                ? (setName({}),
                                  setData({
                                    ccarId:
                                      counter.genericReducer.infoStoreCard
                                        .ccar_id,
                                    putId: !!permissions?.request?.create
                                      ? "2"
                                      : !!permissions?.purchases?.create
                                      ? "1"
                                      : "3",
                                  }),
                                  (settrigger(trigger + 1),
                                  setLoading(false),
                                  settrigger2(trigger2 + 1)))
                                : setLoading(false),
                            () =>
                              history.push({
                                pathname: "/home/compras/confirmacion",
                                data: {
                                  message: "Pago registrado",
                                  img: paymentMobile,
                                },
                              })
                          )
                        ))
                      : (setLoading(true),
                        dispatch(
                          createPurchase(
                            data,
                            (value) =>
                              !!value
                                ? (setName({}),
                                  setData({
                                    ccarId:
                                      counter.genericReducer.infoStoreCard
                                        .ccar_id,
                                    putId: !!permissions?.request?.create
                                      ? "2"
                                      : !!permissions?.purchases?.create
                                      ? "1"
                                      : "3",
                                  }),
                                  (settrigger(trigger + 1),
                                  setLoading(false),
                                  settrigger2(trigger2 + 1)))
                                : setLoading(false),
                            () =>
                              history.push({
                                pathname: "/home/compras/confirmacion",
                                data: {
                                  message: `Nueva ${
                                    parseInt(data.putId) === 2
                                      ? "solicitud"
                                      : "compra"
                                  } registrada`,
                                  img: paymentMobile,
                                },
                              })
                          )
                        ))
                  }
                >
                  Guardar
                </button>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
}
export default PurchasesMovs;
