import { convertFilterToString } from "../helpers/convertToFilter";
import { URL_VPOCKET, API_VERSION } from "../helpers/constants";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
    GET_LAST_MOVES,
    GET_LIST_CARD,
    GET_NOTIFICATION,
    LOGOUT,
    GET_CARD_PURCHASES
} from "./actionTypes";
const MySwal = withReactContent(Swal);


export const listCard = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}userCards?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            const { results, rowTotal } = res;
            dispatch({
                type: GET_LIST_CARD,
                payload: results,
                total: rowTotal,
            });
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar las tarjetas",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const lastMoves = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}lastMoves?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            const { results, rowTotal } = res;
            dispatch({
                type: GET_LAST_MOVES,
                payload: results,
                total: rowTotal,
            });
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar ultimos movimientos",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};

export const getCardPurchases = (objFilters) => (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}purchase?${filters}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            const { results, rowTotal } = res;
            dispatch({
                type: GET_CARD_PURCHASES,
                payload: results,
                total: rowTotal,
            });
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar las transacciones",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};

export const notification = (objFilters) => (dispatch, getCreate) => {
    // let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}notifications`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.error === "Authorization") {
                MySwal.fire({
                    icon: "info",
                    title: "Su sesión ha expirado",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar"
                }).then((result) => {
                    dispatch({
                        type: LOGOUT,
                    })
                })
            } else {
                const { results, rowTotal, countNoRead } = res;
                dispatch({
                    type: GET_NOTIFICATION,
                    payload: results,
                    total: rowTotal,
                    totalNoRead: countNoRead,

                });
            }
        })
        .catch((err) => {
            console.error(err.message);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al cargar las notificaciones",
                footer: "Si el problema persiste comuníquese con un asesor.",
            });
        });
};
export const editNotification = (data, doAfter) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}notifications`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.success) {
                if (!!data.type && data.type === "1") {
                    toast.success('Compra aprobada', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                doAfter()
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
        });
}
export const purchaseApproval = (data, doAfter) => (dispatch, getCreate) => {
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_VPOCKET}${API_VERSION}purchase`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.success) {
                doAfter()
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: res.message,
                    footer: "Si el problema persiste comuníquese con un asesor.",
                });
            }
        })
        .catch((res) => {
        });
}