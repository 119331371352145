import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import { styled } from "@mui/material/styles";
import "../../index.scss";
import Header from "../Header/header";
import clearcard from "../../assets/img/icons/LightBlue.svg";
import whitecard from "../../assets/img/icons/White.svg";
import darkcard from "../../assets/img/icons/DarkBlue.svg";
import Master from "../../assets/img/icons/masterCard.svg";
import Visa from "../../assets/img/icons/visa.svg";
import Discover from "../../assets/img/icons/discover.svg";
import Amex from "../../assets/img/icons/americanexpress.svg";
import imgIcon from "../../assets/img/icons/imgIcon.svg";
import logoCOFCA from "../../assets/img/logoCOFCA.png";
import { useDispatch, useSelector } from "react-redux";
import { lastMoves, listCard, notification } from "../../actions/homeAction";
import ChevronRight from "@mui/icons-material/ChevronRightRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ModalNew from "../Layouts/ModalNew";
import { useHistory } from "react-router";
import CreateCardCredit from "../CardCredit/createCardCredit";
import {
  editCreditCard,
  listUser,
  userByCard,
} from "../../actions/genericAction";
import {
  GET_POSITION_APP,
  GET_STORE_CARD,
  GET_USERS_BY_CARD,
  SET_SELECTED_USER_DETAIL,
} from "../../actions/actionTypes";
import ViewUserCard from "./viewUserCard";
import CardMovement from "../CardCredit/cardMovement";
import { Switch, TextField } from "@mui/material";
import { convertDateToLatinFormat2 } from "../../helpers/convertDateFormat";
import Clip from "@mui/icons-material/AttachFileRounded";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Atras from "@mui/icons-material/ArrowBackIosNewRounded";
import Style from "../Layouts/genericStyles.module.scss";
import Navigation from "../Nav/navigation";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function Home(props) {
  const numberWithCommas = (x) => {
    if(x){
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(".");
    }else{
      return ""
    }
  };
  let meses = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];
  function diaSemana(x) {
    let date = new Date(x);
    var fechaNum = date.getUTCDate();
    var mes_name = date.getMonth();
    return fechaNum + " " + meses[mes_name];
  }
  const counter = useSelector((state) => state);
  const permissions = counter?.loginReducer?.user_data?.permissions;
  const dispatch = useDispatch();
  const history = useHistory();
  const [details, setDetails] = useState({
    show: false,
    showUser: false,
    showModal: false,
  });
  const [trigger, setTrigger] = useState(1);
  const [dataCard, setDataCard] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalPresentation, setModalPresentation] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [statusModal, setStatusModal] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const infoModal = (e) => {
    setDataModal(e);    
    setShowModal(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    background: !!dataModal.pur_file_url
      ? `url(${dataModal.pur_file_url}) `
      : "",
    p: 1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  };
  useEffect(() => {
    if (!!permissions?.cards?.read) {
      dispatch(listCard({ putId: 1 }));
    }
    dispatch(lastMoves({ limit: "10" }));
    dispatch(notification());
  }, [trigger, details.show]);
  useEffect(() => {
    dispatch({
      type: GET_POSITION_APP,
      payload: "",
    });
  }, [details.show]);
  const renderCardList = () => {
    let tempList = [];
    if (Array.isArray(counter?.homeReducer?.card)) {
      counter?.homeReducer?.card.map((item, index) => {
        item.cards.map((x) => {
          tempList.push(
            <Col
              className="mt-4"
              key={index + 1445}
              onClick={() => (
                setDetails({ ...details, showModal: true }),
                setDataCard(x),
                dispatch(listUser()),
                dispatch(userByCard({ ccar_id: x.ccar_id }))
              )}
            >
              <div
                className="cardCredit"
                style={{
                  backgroundImage: `url(${
                    x.ccar_franchise === "VISA"
                      ? whitecard
                      : x.ccar_franchise === "DISCOVER"
                      ? clearcard
                      : x.ccar_franchise === "MASTER CARD"
                      ? darkcard
                      : x.ccar_franchise === "AMERICAN EXPRESS"
                      ? whitecard
                      : whitecard
                  })`,
                  backgroundSize: "cover",
                }}
              >
                {/* <img src={Logo} alt="2222" style={{ position: "absolute" }} /> */}
                <p
                  className={
                    x.ccar_franchise === "DISCOVER" ||
                    x.ccar_franchise === "MASTER CARD"
                      ? "nameBank"
                      : "nameBankGray"
                  }
                >
                  {x.bank}
                </p>
                <p
                  className={
                    x.ccar_franchise === "DISCOVER" ||
                    x.ccar_franchise === "MASTER CARD"
                      ? "codeBank"
                      : "codeBankGray"
                  }
                >
                  {x.ccarNbr}
                </p>
                <p
                  className={
                    x.ccar_franchise === "DISCOVER" ||
                    x.ccar_franchise === "MASTER CARD"
                      ? "nameUserCard"
                      : "nameUserCardGray"
                  }
                >
                  {x.fullName}
                </p>
                <div className="d-flex justify-content-between">
                  <p
                    className={
                      x.ccar_franchise === "DISCOVER" ||
                      x.ccar_franchise === "MASTER CARD"
                        ? "nameUserCard"
                        : "nameUserCardGray"
                    }
                  >
                    {x.compTradename}
                  </p>
                  <p className="logoCard">
                    <img
                      src={
                        x.ccar_franchise === "VISA"
                          ? Visa
                          : x.ccar_franchise === "DISCOVER"
                          ? Discover
                          : x.ccar_franchise === "MASTER CARD"
                          ? Master
                          : x.ccar_franchise === "AMERICAN EXPRESS"
                          ? Amex
                          : Amex
                      }
                      style={{ width: "60px" }}
                      alt="1"
                    />
                  </p>
                </div>
              </div>
            </Col>
          );
        });
      });
    }
    return tempList;
  };
  const close = () => {
    setDetails({ ...details, showModal: false });
    dispatch({
      type: GET_USERS_BY_CARD,
      payload: [],
      total: 0,
    });
  };
  const changeStatus = (e, id) => {
    dispatch(
      editCreditCard(
        { status: e === true ? "enabled" : "disabled", ccar_id: id },
        () => (
          setTrigger(trigger + 1), setDetails({ ...details, showModal: false })
        )
      )
    );
  };

  const handleDownloadFiles = () => {
    if(dataModal.purFiles?.length > 0) {
        dataModal.purFiles?.forEach(file => {
            if(!!file.url) window.open(file.url)
        })
    }else{
        Swal.fire({
            icon:"info",
            title:"La compra no tiene facturas asociadas"
        })
    }
  }

  const renderLastMovesList = () => {
    let tempList = [];
    if (Array.isArray(counter?.homeReducer?.lastMoves)) {
      counter?.homeReducer?.lastMoves.forEach((item, index) => {
        tempList.push(
          <div
            className="d-flex mt-3 p-2 border-top border-bottom border-primary"
            key={index + 7588}
            onClick={() => infoModal(item)}
          >
            <div className="col-2 align-self-center">
              <span className="fw-bold textC-blue">
                {diaSemana(item.pur_date)}
              </span>
            </div>
            <div className="d-block col-8 ">
              <div>
                <span className="fw-bold textC-deepBlue">
                  $ {numberWithCommas(item.pur_amount)}
                </span>
              </div>
              <div>
                <span className="textC-gray">
                  {item.ban_name} - {item.ccarNbrShort}
                </span>
              </div>
              <div>
                <span className="textC-gray" style={{ fontWeight: "500" }}>
                  {item.usr_names} {item.usr_surnames}
                </span>
              </div>
            </div>
            <div className="col-2 align-self-center">
              <ChevronRight sx={{ color: "#114562", fontSize: "40px" }} />
            </div>
          </div>
        );
      });
    }
    return tempList;
  };
  const renderHome = (
    <div style={{ overflowX: "hidden" }}>
      <Header></Header>
      <div className="p-3 mb-4">
        <h1>Tus tarjetas</h1>
        <Row className="d-block">
          {renderCardList()}
          {!!permissions?.cards?.create ? (
            <Col xs={12} className="mt-4">
              <div>
                <button
                  className="btnDark80 text-start"
                  onClick={() => history?.push("/home/crearTarjeta")}
                >
                  <AddCircleRoundedIcon sx={{ marginRight: "10px" }} />
                  Agregar tarjeta
                </button>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <h1 className="mt-3">Últimos movimientos</h1>
        <Row className="d-block">{renderLastMovesList()}</Row>
      </div>
      <ModalNew
        show={details.showModal}
        onHide={close}
        title={"¿Qué deseas hacer?"}
        btnYesName={"Agregar"}
        size={"400"}
      >
        <div>
          <Col
            className="mt-4"
            key={"11122e"}
            onClick={() => setDetails({ ...details, showModal: true })}
          >
            <button onClick={()=>{
              history.push("/home/compras");
              dispatch({ type: GET_STORE_CARD, payload: dataCard });
            }} className="text-decoration-none bg-transparent w-100 ">
            <div
              className="cardCredit d-flex p-3 pb-0"
              style={{
                height: "70px",
                backgroundImage: `url(${clearcard})`,
                backgroundSize: "cover",
              }}
              // style={{
              //     backgroundColor: item.ccar_franchise === "Visa" ? "white" :
              //         item.ccar_franchise === "Discover" ? "#01A0F6" :
              //             item.ccar_franchise === "Master" ? "#005DBF" :
              //                 item.ccar_franchise === "Amex" ? "white" : ""
              // }}
            >
              {/* <img src={Logo} alt="2222" style={{ position: "absolute" }} /> */}
              <div className="w-100">
                <p className="nameBank mb-0 pt-0">{dataCard.bank}</p>
                <p
                  className="text-white fw-bold pt-0"
                  style={{ fontSize: "14px", paddingLeft: "15px" }}
                >
                  {dataCard.ccarNbr}
                </p>
              </div>
              <div className="align-self-end pr-3">
                <p className="">
                  <img src={Master} style={{ width: "60px" }} alt="1" />
                </p>
              </div>
            </div>
            </button>
          </Col>
        </div>
        <Row className="mt-3" style={{ justifyContent: "center" }}>
          <Col
            xs={6}
            className="borderC-blue border-r15 m-1 d-block"
            style={{ width: "130px", height: "110px" }}
            onClick={() =>
              !!permissions?.cards?.update
                ? (dispatch({
                    type: GET_POSITION_APP,
                    payload: "editar",
                  }),
                  setDetails({ ...details, show: true, info: dataCard }))
                : ""
            }
          >
            <div className="mt-5">
              <SettingsIcon sx={{ color: "#2dcb95" }} />
            </div>
            <div>
              <span className="textC-deepBlue fw-bold">Editar</span>
            </div>
          </Col>
          <Col
            xs={6}
            className="borderC-blue border-r15 m-1 d-block"
            style={{ width: "130px", height: "110px" }}
            onClick={() =>
              !!permissions?.user_card?.read
                ? (setDetails({ ...details, showUser: true, info: dataCard }),
                  dispatch({
                    type: GET_POSITION_APP,
                    payload: "user",
                  }))
                : ""
            }
          >
            <div className="mt-5">
              <PersonAddRoundedIcon sx={{ color: "#2dcb95" }} />
            </div>
            <div>
              <span className="textC-deepBlue fw-bold">Usuarios</span>
            </div>
          </Col>
          <Col
            xs={6}
            className="borderC-blue border-r15 m-1 d-block"
            style={{ width: "130px", height: "110px" }}
            onClick={() => {
              dispatch({
                type: SET_SELECTED_USER_DETAIL,
                payload: counter?.loginReducer?.user_data,
                card: dataCard,
              });
              history?.push("/home/reportes/");
            }}
          >
            <div className="mt-5">
              <AttachMoneyRoundedIcon sx={{ color: "#2dcb95" }} />
            </div>
            <div>
              <span className="textC-deepBlue fw-bold">Informe </span>
            </div>
          </Col>
          <Col
            xs={6}
            className="borderC-blue border-r15 m-1 d-block"
            style={{ width: "130px", height: "110px" }}
            onClick={() =>
              !!permissions?.movs?.read
                ? (setDetails({ ...details, showMovs: true, info: dataCard }),
                  dispatch({
                    type: GET_POSITION_APP,
                    payload: "movs",
                  }))
                : ""
            }
          >
            <div className="mt-5">
              <FormatListBulletedRoundedIcon sx={{ color: "#2dcb95" }} />
            </div>
            <div>
              <span className="textC-deepBlue fw-bold">Movimientos</span>
            </div>
          </Col>
          <div className="d-flex justify-content-around">
            {permissions?.cards?.delete && (
              <div className="d-flex">
                <Switch
                  checked={dataCard.carStatus === "enabled"}
                  onChange={(e) =>
                    changeStatus(e.target.checked, dataCard.ccar_id)
                  }
                  color="success"
                />
                <p
                  className={Style.toggleTitle}
                  style={{ margin: "0 6px", alignSelf: "center" }}
                >
                  Uso Habilitado
                </p>
              </div>
            )}
            <div className="d-flex">
              <button className="bg-transparent" onClick={()=>setModalPresentation(true)}>
                <img src={imgIcon} alt="presentation card" />
              </button>
            </div>
          </div>
        </Row>
      </ModalNew>
      <ModalNew
        show={showModal}
        onHide={() => setShowModal(false)}
        title={
          dataModal?.transactionType === "Abono" ? (
            <h1 className="textC-green">Detalle de pago</h1>
          ) : (
            "Detalle de compra"
          )
        }
        size={"400"}
      >
        <Row>
          <Col xs={12} className="px-4 mt-3">
            <TextField
              key={trigger}
              className="w-100"
              label="Tarjeta utilizada"
              value={dataModal?.ban_name + " - " + dataModal?.ccarNbrShort}
              variant="outlined"
              disabled
            />
          </Col>
          <Col xs={12} className="px-4 mt-3">
            <TextField
              key={trigger}
              className="w-100"
              label="Concepto"
              value={dataModal?.pur_description}
              variant="outlined"
              disabled
            />
          </Col>
          {dataModal.transactionType !== "Abono" ? (
            <Col xs={12} className="px-4 mt-3">
              <TextField
                key={trigger}
                className="w-100"
                label="Tipo de gasto"
                value={dataModal?.ext_name}
                variant="outlined"
                disabled
              />
            </Col>
          ) : (
            ""
          )}
          <Col xs={12} className="px-4 mt-3">
            <TextField
              key={trigger}
              type="date"
              className="w-100"
              label="Fecha"
              value={dataModal?.pur_date}
              variant="outlined"
              disabled
            />
          </Col>
          <Col xs={12} className="px-4 mt-3">
            <TextField
              key={trigger}
              className="w-100"
              label="Valor"
              value={
                !!dataModal?.pur_amount
                  ? "$" + numberWithCommas(dataModal?.pur_amount)
                  : ""
              }
              variant="outlined"
              disabled
            />
          </Col>
          {dataModal.transactionType === "Recurrente" ? (
            <Col xs={12} className="px-4 mt-3">
              <TextField
                key={trigger}
                className="w-100"
                label="Tipo de pago"
                value={dataModal.transactionType}
                variant="outlined"
                disabled
              />
            </Col>
          ) : (
            ""
          )}
          {dataModal.transactionType === "Recurrente" ? (
            <Col xs={12} className="px-4 mt-3">
              <TextField
                key={trigger}
                className="w-100"
                label="Cuotas"
                value={dataModal.duesQty}
                variant="outlined"
                disabled
              />
            </Col>
          ) : (
            ""
          )}
          {dataModal.transactionType === "Recurrente" ? (
            <Col xs={12} className="px-4 mt-3 d-flex">
              <div className="text-start col-6">
                <span className="textC-gray">
                  <b>Inicio </b>
                  {convertDateToLatinFormat2(dataModal?.date)}
                </span>
              </div>

              <div className="text-end col-6">
                <span className="textC-gray text-end">
                  <b> Final </b>
                  {convertDateToLatinFormat2(dataModal?.finalDate)}
                </span>
              </div>
            </Col>
          ) : (
            ""
          )}
          {dataModal?.transactionType !== "Abono" ? (
            <>
              <Col xs={12} className="px-4 mt-3">
                <span className="fw-bold textC-deepBlue">
                  Detalle de la factura
                </span>
              </Col>
              <Col xs={3} className="px-4 mt-3"></Col>
              <Col xs={9} className="px-4 mt-3">
                <div className="text-end">
                  <button
                    className="btnModalGreen "
                    onClick={()=>handleDownloadFiles()}
                    // disabled={!!info.pur_justification ? false : true}
                  >
                    <Clip sx={{ color: "#fff" }} />
                    Ver Factura
                  </button>
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col xs={3} className="px-4 mt-3"></Col>
          <Col xs={9} className="px-4 mt-3">
            <div className="text-end">
              <button
                className="btnModalPrimary "
                onClick={() => setShowModal(false)}
                // disabled={!!info.pur_justification ? false : true}
              >
                Aceptar
              </button>
            </div>
          </Col>
        </Row>
      </ModalNew>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Atras
            className="textC-deepBlue "
            onClick={handleClose}
            sx={{ fontSize: "3rem" }}
          />
        </Box>
      </Modal>
      <ModalNew
        show={modalPresentation}
        onHide={()=>setModalPresentation(false)}
        title={"Información de facturación"}
        // btnYesName={"Agregar"}     
        size={"400"}
      >
        
        <div className="d-flex shadow-sm p-3 mb-5 bg-body rounded">
            <div className="col">

                <div className="d-flex">
                    <img src={logoCOFCA} alt="logo COFCA" width="50%" />
                </div>
                <div className="d-flex p-3">
                    <div className="col-1"/>
                    <div className="col">
                        <hr></hr>
                        <h6 className="fw-bold">{dataCard.compName}</h6>
                        <p>NIT: {dataCard.compNit}</p>
                        <p>Correo electrónico: {dataCard.compEmail}</p>
                        <p>Teléfono: {dataCard.compPhone}</p>
                        <p>Dirección: {dataCard.compAddress}</p>
                    </div>
                </div>
            </div>
        </div>
          
    </ModalNew>
      
      <Navigation info={0}></Navigation>
    </div>
  );
  return !!details.show ? (
    <CreateCardCredit
      setDetails={setDetails}
      info={details.info}
      show={details.show}
    />
  ) : !!details.showUser ? (
    <ViewUserCard
      setDetails={setDetails}
      info={details.info}
      show={details.showUser}
    />
  ) : !!details.showMovs ? (
    <CardMovement
      setDetails={setDetails}
      info={details.info}
      show={details.showMovs}
    />
  ) : (
    renderHome
  );
}
export default Home;
