import React, { useState } from "react";
import { TextField } from "@mui/material";
import "../../index.scss"
import { useHistory } from "react-router";
import Balloon from "../../assets/img/icons/globotrue.svg"
import { useDispatch } from "react-redux";
import { EmailForgotPassword } from "../../actions/loginActions";

function ForgotPassword(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [show, setShow] = useState({ show: false })
    const [params, setParams] = useState({
        email: "",
    })
    const submit = () => {
        dispatch(EmailForgotPassword(params, () => setShow({ show: true })))
    }
    return (
        <div>
            <div>
                <div className="d-flex p-3" onClick={() => history?.push("/login")}>
                    < span className="material-icons textC-green" style={{ fontSize: "40px" }}>
                        chevron_left
                    </span>
                    <label className="fw-bold" style={{ alignSelf: "center" }}>
                        Atrás
                    </label>

                </div>
                <div style={{ padding: "30% 8.9%" }}
                >
                    {show.show === false ? <div>
                        <h1>Restablecer contraseña</h1>
                        <p>
                            Ingrese su correo electrónico y le enviaremos una contraseña
                            provisional de ingreso
                        </p>
                        <TextField
                            className="w-100 mt-5"
                            label="Correo electrónico"
                            type="text"
                            id="custom-css-outlined-input"
                            onChange={(e) =>
                                setParams({
                                    ...params,
                                    email: e.target.value,
                                })}
                        />
                        <button className="mt-3 btnDark"
                            onClick={() => submit()}
                        >
                            Enviar instrucciones
                        </button>
                    </div> : <div>
                        <div className="text-center">
                            <img src={Balloon} alt="Balloon" srcset="" />
                        </div>
                        <h1 className="mt-3 text-center">Revisa tu correo</h1>
                        <p>
                            Hemos enviado un correo con las instrucciones de
                            recuperación de contraseñan
                        </p>
                        <button className="mt-3 btnDark"
                            onClick={() => history?.push("/login")}
                        >
                            Aceptar
                        </button>
                    </div>}
                </div>
            </div>
        </div >
    )

}
export default ForgotPassword;
