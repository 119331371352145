import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
// import { styled } from "@mui/material/styles";
import "../../index.scss"
import Notifications from '@mui/icons-material/NotificationsRounded';
import Navigation from "../Nav/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Badge from '@mui/material/Badge';
import Logo from "../../assets/img/icons/logoclear.svg"
import { LOGOUT } from "../../actions/actionTypes";
import { FormHelperText, TextField } from "@mui/material";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { updatePassword } from "../../actions/genericAction";
import { TailSpin } from "react-loader-spinner";

function MyAccount(props) {
    const counter = useSelector((state) => state);
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const permissions = counter?.loginReducer?.user_data?.permissions;
    const [data, setData] = useState([]);
    const [validate, setValidate] = useState([]);
    const [trigger, setTrigger] = useState(1);
    return (
        <div style={{ overflowX: "hidden" }}>
            {!!loading ?
                <div className="loading">
                    <TailSpin
                        color="blue"
                    />
                </div>
                : ""}
            <div className="bgHeaderC-deepBlue" >
                <Row className="d-flex px-4 pt-3">
                    <Col xs={10}>
                        <img className="pt-1" src={Logo} alt="logo" />
                    </Col>
                    {!!permissions?.notifications?.read ?
                        <Col className="bgNotifi" xs={2} onClick={() => history?.push("/home/notificaciones")}>
                            <Badge color="primary" variant="dot" invisible={counter.homeReducer.totalNoRead >= 1 ? false : true}  >
                                <Notifications
                                    sx={{ color: "#0db7e1" }}
                                />
                            </Badge>
                        </Col>
                        : ""}

                    <Row className="d-block">
                        <Col className="mt-5">
                            <span className="text-white fs-1 fw-bold mt-5">Mi cuenta</span>
                        </Col>
                    </Row>
                </Row>
            </div >
            <div className="p-3 mb-4">
                <Row className="d-block">
                    <Col xs={12}>
                        <span class="fw-bold textC-deepBlue">Información general</span>
                    </Col>
                    <Col xs={12}>
                        <TextField
                            className="w-100 mt-2"
                            label="Nombres"
                            type="text"
                            id="custom-css-outlined-input"
                            value={counter?.loginReducer?.user_data?.usr_names}
                            disabled
                        // onChange={(e) =>
                        //     setParams({
                        //         ...params,
                        //         email: e.target.value,
                        //     })}
                        />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            className="w-100 mt-3"
                            label="Apellidos"
                            type="text"
                            id="custom-css-outlined-input"
                            value={counter?.loginReducer?.user_data?.usr_surnames}
                            disabled
                        // onChange={(e) =>
                        //     setParams({
                        //         ...params,
                        //         email: e.target.value,
                        //     })}
                        />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            className="w-100 mt-3"
                            label="Cargo"
                            type="text"
                            id="custom-css-outlined-input"
                            value={counter?.loginReducer?.user_data?.role}
                            disabled
                        // onChange={(e) =>
                        //     setParams({
                        //         ...params,
                        //         email: e.target.value,
                        //     })}
                        />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            className="w-100 mt-3"
                            label="Correo electrónico"
                            type="text"
                            id="custom-css-outlined-input"
                            value={counter?.loginReducer?.user_data?.usr_email}
                            disabled
                        // onChange={(e) =>
                        //     setParams({
                        //         ...params,
                        //         email: e.target.value,
                        //     })}
                        />
                    </Col>
                    <Col xs={12} className="mt-4">
                        <span class="fw-bold textC-deepBlue">Cambiar contraseña</span>
                    </Col>
                    <FormHelperText id="component-helper-text">
                        La contraseña debe tener un mínimo de 8 caracteres, una mayúscula, número, e incluir al menos un carácter especial -#!@*($)-
                    </FormHelperText>
                    <Col xs={12}>
                        <TextField
                            key={trigger}
                            className="w-100 mt-3"
                            label="Contraseña anterior*"
                            type="password"
                            id="custom-css-outlined-input"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    oldPassword: e.target.value,
                                })}
                        />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            key={trigger}
                            error={validate.validetePassword != data.newPassword ? true : false}
                            helperText={
                                validate.validetePassword != data.newPassword ?
                                    'Ambas contraseñas deben ser iguales ' : ''}
                            className="w-100 mt-3"
                            label="Nueva contraseña*"
                            type="password"
                            id="custom-css-outlined-input"
                            onChange={(e) =>
                                setValidate({
                                    ...validate,
                                    validetePassword: e.target.value,
                                })}
                        />

                    </Col>
                    <Col xs={12}>
                        <TextField
                            key={trigger}
                            error={validate.validetePassword != data.newPassword ? true : false}
                            helperText={validate.validetePassword != data.newPassword ? 'Ambas contraseñas deben ser iguales ' : ''}
                            className="w-100 mt-3"
                            label="Confirmar contraseña*"
                            type="password"
                            id="custom-css-outlined-input"
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    newPassword: e.target.value,
                                })}
                        />
                    </Col>
                    <Col xs={12} className="px-4 mt-3 text-center">
                        <div >
                            <button className="w-60 btnModalPrimary"
                                disabled={
                                    (!!validate.validetePassword === false || !!data.newPassword === false) ||
                                        validate.validetePassword != data.newPassword ? true : false}
                                onClick={() => (setLoading(true), dispatch(updatePassword(data, () => setLoading(false), setValidate([]), setData([]), setTrigger(trigger + 1))))} >
                                Guardar cambios
                            </button>
                        </div>
                    </Col>
                    <Col xs={12} className="px-4 mt-3 text-center">
                        <div >
                            <span className="w-60 fw-bold textC-deepBlue60 hoverPointer" onClick={() => dispatch({
                                type: LOGOUT,
                            })}>
                                Cerrar sesión
                                <LogoutRoundedIcon sx={{ color: "#2DCB95", fontSize: "30px", marginLeft: "8px" }} />
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
            <Navigation info={2}></Navigation>

        </div >
    )
}
export default MyAccount;
